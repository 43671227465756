import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import axios from "axios";
import Cookies from "js-cookie";
import localforage from 'localforage';

import { withStyles, Typography, Grid, MenuItem, TextField } from "@material-ui/core";

import AppContext from "../AppContext";
import ErrorMessage from "./ErrorMessage";
import CoolingTable from "./CoolingTable";

import styles from "../styles";

const periods = {
  1: {
    count: 1,
    period: "day"
  },
  7: {
    count: 1,
    period: "week"
  },
  14: {
    count: 2,
    period: "weeks"
  },
  30: {
    count: 1,
    period: "month"
  },
  90: {
    count: 3,
    period: "months"
  },
  180: {
    count: 6,
    period: "months"
  },
  365: {
    count: 1,
    period: "year"
  }
};

class CoolingView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      periodAnchorEl: null,
      errorOpen: false,
      loadingMeteringPoints: true,
      meteringPoints: [],
      period: Cookies.get("k2_cooling_period") || "30",
    };
  }

  componentDidMount() {
    var meteringPointsData = [
      localforage.getItem('meteringPoints'),
      localforage.getItem('coolingPeriod')
    ]
    Promise.all(meteringPointsData).then((values) => {
      if (Boolean(values[0]) && Boolean(values[1])) {
        this.setState({
          alarms: values[0],
          period: values[1],
          loadingMeteringPoints: false
        })
      } else {
        this.fetchAllMeteringPoints();
      }
    })
  }

  fetchAllMeteringPoints = () => {
    var thiz = this;

    this.setState({ loadingMeteringPoints: true, meteringPoints: [] });
    function fetchMeteringPoints(token) {
      var headers = { Authorization: "Bearer " + token };
      var params = { limit: 9999999999 };
      return axios.get(thiz.context.config.meteringPointsAPI + "/", { headers, params });
    }
    this.context.keycloak
      .updateToken(5)
      .success(function () {
        axios
          .all([fetchMeteringPoints(thiz.context.keycloak.token)])
          .then(
            axios.spread(function (meteringPointResponse) {
              localforage.setItem('meteringPoints', meteringPointResponse.data);
              thiz.setState({
                meteringPoints: meteringPointResponse.data,
                period: thiz.state.period,
                loadingMeteringPoints: false,
              });
            })
          )
          .catch((error) => {
            console.log(error);
          });
      })
      .error(function () {
        context.keycloak.login();
      });
  };

  handlePeriodChoose = event => {
    Cookies.set("k2_cooling_period", event.target.value, {expires: new Date(Date.now()+1000000000000), sameSite: "lax"});
    this.setState({ period: event.target.value });
  };

  handleErrorClose = () => {
    this.setState({ errorOpen: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <ErrorMessage open={this.state.errorOpen} onClose={this.handleErrorClose} message={this.state.errorMessage} />
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h5" color="textSecondary">
              <FormattedMessage id="cooling" />
            </Typography>
          </Grid>
          <Grid item xs={5} className={classes.AlignRight}>
          </Grid>
          <Grid item xs={1} className={classes.AlignRight}>
            <TextField
              id="standard-select-currency"
              select
              label="Period"
              value={this.state.period}
              onChange={this.handlePeriodChoose}
            >
              {Object.keys(periods).map(period => (
                <MenuItem key={period} value={period}>
                  {periods[period]["count"]}
                  &nbsp;
                  <FormattedMessage id={periods[period]["period"]} />
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <CoolingTable
          meteringPoints={this.state.meteringPoints}
          loading={this.state.loadingMeteringPoints}
          period={this.state.period}
          history={this.props.history}
        />
      </React.Fragment>
    );
  }
}

CoolingView.contextType = AppContext;

export default withStyles(styles)(injectIntl(CoolingView));
