import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Paper, makeStyles, Typography, TextField, MenuItem } from "@material-ui/core";
import Cookies from "js-cookie";
import styles from "../styles";
const useStyles = makeStyles(styles);


export default function ConfigurationLanguage(props) {
  const classes = useStyles();
  const intl = useIntl();
  let initialLanguage = Cookies.get("k2_language")
  if (typeof initialLanguage === "undefined") {
    initialLanguage = "sv";
  }
  var [language, setLanguage] = useState(initialLanguage);

  function updateLanguage(event) {
    const language = String(event.target.value)
    Cookies.set("k2_language", language, {expires: new Date(Date.now()+1000000000000), sameSite: "lax"});
    setLanguage(language);
    props.updateLanguage(language);
  };

  const languages = {
    en: "English",
    sv: "Svenska"
  };

  return (
    <React.Fragment>
      <Paper className={classes.Paper}>
        <Typography variant="h6" color="textSecondary">
          <FormattedMessage id="language"/>
        </Typography>
        <br/>
        <TextField
          className={classes.TextField + " " + classes.MarginRight}
          select
          label={intl.formatMessage({
            id: "language"
          })}
          // defaultValue={theme}
          value={language}
          onChange={updateLanguage}
        >
          {Object.keys(languages).map(lang => (
            <MenuItem key={lang} value={lang}>
              {/* <FormattedMessage id={themes[theme]} /> */}
              {languages[lang]}
            </MenuItem>
          ))}
        </TextField>
      </Paper>
    </React.Fragment>
  );
}
