import React, { useState, useEffect } from "react";
import { Chip } from "@material-ui/core";

export default function ListOfChips(props) {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    setTags(props.tags);
  }, [props]);

  return (
    <React.Fragment>
      {tags.map((text) => {
        return <Chip key={text} label={text} />;
      })}
    </React.Fragment>
  );
}
