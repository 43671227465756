import React from "react";

import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
  IconButton,
  Checkbox
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Select from "react-select";

import AppContext from "../AppContext";

function createSelectOptions(values) {
  var selectOptions = [];
  if (values) {
    for (var value of values) {
      selectOptions.push({
        value,
        label: value
      });
    }
  }
  return selectOptions;
}

class FilterTags extends React.Component {
  constructor(props) {
    super(props);
    var tags = props.tags.map(tag => tag.key);
    this.state = {
      filter: this.convertFromTagsToFilter(props.tags),
      allTags: tags,
      selectedTags: tags
    };
  }

  /**
   *
   * @param {*} filterData
   */
  updateFilterData = filterData => {
    let filterActive = [];
    filterData.forEach(value => {
      if (value.in_filter === true) {
        filterActive.push(value);
      }
    });
    this.props.handleFilterTags(filterActive);
  };

  findTagId = id => {
    let found = false;
    let object = null;
    this.props.filterTags.forEach(filtertag => {
      if (filtertag.key_value === id) {
        found = true;
        object = filtertag;
      }
    });
    return { found, object };
  };

  /**
   * Convert tags to a internal state format
   * @param {*} tags
   */
  convertFromTagsToFilter = tags => {
    let newFilter = [];
    tags.forEach(currentKey => {
      currentKey.val.forEach(currentValue => {
        let idTag = currentKey.key + ":" + currentValue;
        let result = this.findTagId(idTag);
        let oneFilter = {
          in_filter: result.found === true ? result.object.in_filter : false,
          key_value: idTag,
          type_of_filter: result.found === true ? result.object.type_of_filter : "include"
        };
        newFilter.push(oneFilter);
      });
    });
    return newFilter;
  };

  /**
   * Toggle include and exclude in internal state
   */
  toggleIncludeExclude = id => {
    let post = this.state.filter.find(x => x.key_value === id);
    let filter = this.state.filter;
    if (post.type_of_filter === "include") {
      post.type_of_filter = "exclude";
    } else {
      post.type_of_filter = "include";
    }
    this.setState({ filter });
    this.updateFilterData(filter);
  };

  /**
   * Toggle checkbox in internal state
   * @param {*} event
   * @param {*} id
   */
  toggleInFilter = id => {
    let post = this.state.filter.find(x => x.key_value === id);
    let filter = this.state.filter;
    post.in_filter = !post.in_filter;
    this.setState({ filter });
    this.updateFilterData(filter);
  };

  selectTags = tags => {
    var selectedTags = [];
    if (tags) {
      selectedTags = tags.map(tag => tag.value);
    }
    this.setState({ selectedTags });
  };

  render() {
    const tagsToDisplay = this.state.filter.filter(filter =>
      this.state.selectedTags.includes(filter.key_value.split(":")[0])
    );

    return (
      <React.Fragment>
        {this.state.allTags.length > 0 ? (
          <React.Fragment>
            <Select
              value={createSelectOptions(this.state.selectedTags)}
              isMulti
              onChange={this.selectTags}
              options={createSelectOptions(this.state.allTags)}
            />
            <List>
              {tagsToDisplay.map(values => {
                return (
                  <ListItem key={values.key_value} dense>
                    <ListItemIcon>
                      <Checkbox
                        color="primary"
                        edge="start"
                        checked={values.in_filter}
                        tabIndex={-1}
                        onClick={() => this.toggleInFilter(values.key_value)}
                      />
                    </ListItemIcon>
                    <ListItemText id={"textid-" + values.key_value} primary={values.key_value} />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" onClick={() => this.toggleIncludeExclude(values.key_value)}>
                        {values.type_of_filter === "exclude" ? <RemoveIcon /> : <AddIcon />}
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  }
}

FilterTags.contextType = AppContext;

export default FilterTags;
