
export const ENERGY_USAGE_CHANGE = "ENERGY_USAGE_CHANGE"
export const ACTION_OTHER = "ACTION_OTHER"
export const COMP_OTHER = "COMP_OTHER"
export const FAULT_DISTR_SYST = "FAULT_DISTR_SYST"

export const protocol_menus = {
    "ACTION": [
        "ACTION_CHANGED_UNIT",
        "ACTION_REPAIRED",
        "ACTION_MAINTENANCE_PLANNED",
        "ACTION_SETTING_CHANGED",
        ACTION_OTHER
    ],
    "CAUSE": [
        "FAULT_PRIM_DISTR_HEAT",
        "FAULT_DISTR_HEAT_MTR",
        "FAULT_HEAT_SYST",
        "FAULT_DOM_HOT_WTR_SYST",
        FAULT_DISTR_SYST,
        ENERGY_USAGE_CHANGE
    ],
    "ENERGY_USAGE_CHANGE": [
        "CHANGE_HEAT_NEEDS",
        "CHANGE_BUSINESS",
        "CHANGE_COMPL_SUPPL",
        "CHANGE_NRG_EFF_MEAS",
        "CHANGE_FIXED_FAULT",
        "CHANGE_INTENT_SHUTOFF",
        "CHANGE_UNINT_SHUTOFF",
        "CHANGE_OTHER"
    ],
    "FAULT_DISTR_HEAT_MTR": [
        "COMP_COMM_UNIT",
        "COMP_FLOW_SENS",
        "COMP_INTEGR",
        "COMP_RET_TEMP_SENS",
        "COMP_SUPPLY_TEMP_SENS",
        "COMP_COM_FAULTY",
        "COMP_OTHER_DIST_EQUIP",
        "COMP_MEETER_READING",
        "COMP_VALIDATION",
        COMP_OTHER
    ],
    "FAULT_DOM_HOT_WTR_SYST": [
        "COMP_ACT",
        "COMP_BAL_VLV",
        "COMP_CHECK_VLV",
        "COMP_CIRC_PUMP",
        "COMP_CONTR_VLV",
        "COMP_CONTROLLER",
        "COMP_HEAT_EXCH",
        "COMP_MANOM",
        "COMP_MIX_VLV",
        "COMP_SHUTOFF_VLV",
        "COMP_TAP_MIX_MIX_VLV",
        "COMP_MIX_VLV_TEMP_SENS",
        "COMP_SEC_RET_TEMP_SENS",
        "COMP_SEC_SUPPLY_TEMP_SENS",
        COMP_OTHER
    ],
    "FAULT_HEAT_SYST": [
        "COMP_ACT",
        "COMP_BAL_VLV",
        "COMP_CIRC_PUMP",
        "COMP_CONTR_VLV",
        "COMP_CONTROLLER",
        "COMP_EXP_VESSEL",
        "COMP_FAN_COIL_UNIT",
        "COMP_FILL_VLV_CHK_VLV",
        "COMP_FILTER",
        "COMP_UNDERFLOOR_HEAT",
        "COMP_HEAT_EXCH",
        "COMP_MANOM",
        "COMP_RADI",
        "COMP_SAFETY_VLV",
        "COMP_SHUNT_GRP",
        "COMP_SHUTOFF_VLV",
        "COMP_THERM_RAD_VLV",
        "COMP_INDR_TEMP_SENS",
        "COMP_OUTD_TEMP_SENS",
        "COMP_SEC_RET_TEMP_SENS",
        "COMP_SEC_SUPPLY_TEMP_SENS",
        "COMP_VENTIL_UNIT",
        COMP_OTHER
    ],
    "FAULT_PRIM_DISTR_HEAT": [
        "COMP_AIR_BLEED_VLV",
        "COMP_BYPASS_VLV",
        "COMP_DIFF_PRESS",
        "COMP_DRAIN_VLV",
        "COMP_FILTER",
        "COMP_FLUSH",
        "COMP_FUSE_ELECTRICITY_SUPPLY",
        "COMP_MANOM",
        "COMP_PIPE",
        "COMP_SERV_CONN_ISOL_VLV",
        "COMP_THROT",
        COMP_OTHER
    ],
    "STATUS": [
        "STATUS_HANDLED",
        "STATUS_PARTLY_HANDLED",
        "STATUS_NOT_HANDLED"
    ]
};



export const cause_component_dic = {
    "FAULT_PRIM_DISTR_HEAT": protocol_menus.FAULT_PRIM_DISTR_HEAT,
    "FAULT_DISTR_HEAT_MTR":protocol_menus.FAULT_DISTR_HEAT_MTR,
    "FAULT_HEAT_SYST":protocol_menus.FAULT_HEAT_SYST,
    "FAULT_DOM_HOT_WTR_SYST":protocol_menus.FAULT_DOM_HOT_WTR_SYST,
    "FAULT_DISTR_SYST":protocol_menus.FAULT_DISTR_SYST
}