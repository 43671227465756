import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Paper, makeStyles, Typography, TextField, MenuItem, Tooltip } from "@material-ui/core";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Cookies from "js-cookie";
import styles from "../styles";
const useStyles = makeStyles(styles);


export default function ConfigurationCharts() {
  const classes = useStyles();
  const intl = useIntl();

  function readCookie(cookieName, values, defaultValue=null) {
    const val = Cookies.get(cookieName);
    if (typeof val === undefined || values.indexOf(val) === -1) {
      if (defaultValue === null) {
        return values[0];
      } else {
        return defaultValue;
      }
    } else {
      return val;
    }
  }

  var [theme, setTheme] = useState(readCookie("k2_themename", ["default", "classic"]));
  var [chartLinking, setChartLinking] = useState(readCookie("k2_chartLinking", ["enabled", "disabled"]));
  var [chartHeight, setChartHeight] = 
    useState(parseInt(readCookie("k2_chartHeight", [...Array(19).keys()].map(x => ""+(x*50+100)), "400")));
  var [chartWidths, setChartWidths] = 
    useState(parseInt(readCookie("k2_chartWidths", ["4", "5", "6", "7", "8"], "6")));
  var [chartSymbol, setChartSymbol] = 
    useState(readCookie("k2_chartSymbol", ["circle", "square", "diamond", "cross", "x", "triangle-up"]));
  var [chartSymbolSize, setChartSymbolSize] = 
    useState(parseInt(readCookie("k2_chartSymbolSize", [...Array(20).keys()].map(x => ""+(x+1)), "4")));

  function handleThemeChange(event) {
    const newValue = String(event.target.value)
    Cookies.set("k2_themename", newValue, {expires: new Date(Date.now()+1000000000000), sameSite: "lax"});
    setTheme(newValue);
  };
  function handleChartLinkingChange(event) {
    const newValue = String(event.target.value)
    Cookies.set("k2_chartLinking", newValue, {expires: new Date(Date.now()+1000000000000), sameSite: "lax"});
    setChartLinking(newValue);
  };
  function handleChartSymbolChange(event) {
    const newValue = String(event.target.value)
    Cookies.set("k2_chartSymbol", newValue, {expires: new Date(Date.now()+1000000000000), sameSite: "lax"});
    setChartSymbol(newValue);
  };
  function handleChartSymbolSizeChange(event) {
    const newValue = String(event.target.value)
    Cookies.set("k2_chartSymbolSize", newValue, {expires: new Date(Date.now()+1000000000000), sameSite: "lax"});
    setChartSymbolSize(newValue);
  };
  function handleChartWidthsChange(event) {
    const newValue = String(event.target.value)
    Cookies.set("k2_chartWidths", newValue, {expires: new Date(Date.now()+1000000000000), sameSite: "lax"});
    setChartWidths(newValue);
  };
  function handleChartHeightChange(event) {
    const newValue = String(event.target.value)
    Cookies.set("k2_chartHeight", newValue, {expires: new Date(Date.now()+1000000000000), sameSite: "lax"});
    setChartHeight(newValue);
  };

  const themes = {
    default: "chart.theme.default",
    classic: "chart.theme.classic"
  };
  const linkings = {
    enabled: "chart.chart_linking.enabled",
    disabled: "chart.chart_linking.disabled"
  }
  const symbols = {
    circle: "chart.symbol.circle",
    square: "chart.symbol.square",
    diamond: "chart.symbol.diamond",
    cross: "chart.symbol.cross",
    x: "chart.symbol.x",
    "triangle-up": "chart.symbol.triangle"
  }
  const widths = {
    4: "4:8",
    5: "5:7",
    6: "6:6",
    7: "7:5",
    8: "8:4",
  }

  return (
    <React.Fragment>
      <Paper className={classes.Paper}>
        <Typography variant="h6" color="textSecondary">
          <FormattedMessage id="configuration.charts"/>
        </Typography>
        <br/>
        <TextField
          className={classes.TextField + " " + classes.MarginRight}
          select
          label={intl.formatMessage({
            id: "chart.theme"
          })}
          value={theme}
          onChange={handleThemeChange}
        >
          {Object.keys(themes).map(theme => (
            <MenuItem key={theme} value={theme}>
              <FormattedMessage id={themes[theme]} />
            </MenuItem>
          ))}
        </TextField>
        <TextField
          className={classes.TextField + " " + classes.MarginRight}
          select
          label={intl.formatMessage({
            id: "chart.chart_linking"
          })}
          value={chartLinking}
          onChange={handleChartLinkingChange}
        >
          {Object.keys(linkings).map(linking => (
            <MenuItem key={linking} value={linking}>
              <FormattedMessage id={linkings[linking]} />
            </MenuItem>
          ))}
        </TextField>
        <TextField
          className={classes.TextField + " " + classes.MarginRight}
          select
          label={(<FormattedMessage id={"chart.height"}/>)}
          value={chartHeight}
          onChange={handleChartHeightChange}
        >
          {[...Array(19).keys()].map(i => (
            <MenuItem key={50*i+100} value={50*i+100}>
              {50*i+100}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          className={classes.TextField + " " + classes.MarginRight}
          select
          label={(<FormattedMessage id={"chart.widths"}/>)}
          value={chartWidths}
          onChange={handleChartWidthsChange}
        >
          {Object.keys(widths).map(width => (
            <MenuItem key={width} value={width}>
              {widths[width]}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          className={classes.TextField + " " + classes.MarginRight}
          select
          label={intl.formatMessage({
            id: "chart.symbol"
          })}
          value={chartSymbol}
          onChange={handleChartSymbolChange}
        >
          {Object.keys(symbols).map(symbol => (
            <MenuItem key={symbol} value={symbol}>
              <FormattedMessage id={symbols[symbol]}/>
            </MenuItem>
          ))}
        </TextField>
        <TextField
          className={classes.TextField + " " + classes.MarginRight}
          select
          label={intl.formatMessage({
            id: "chart.symbol.size"
          })}
          value={chartSymbolSize}
          onChange={handleChartSymbolSizeChange}
        >
          {[...Array(20).keys()].map(i => (
            <MenuItem key={i+1} value={i+1}>
              {i+1}
            </MenuItem>
          ))}
        </TextField>
        <Tooltip
          placement={"right"}
          title={(
            <div>
              <FormattedMessage id={"chart.theme.tooltip.text"}/>
              <br/>
              <FormattedMessage id={"chart.chart_linking.tooltip.text"}/>
              <br/>
              <FormattedMessage id={"chart.height.tooltip.text"}/>
              <br/>
              <FormattedMessage id={"chart.widths.tooltip.text"}/>
              <br/>
              <FormattedMessage id={"chart.symbol.tooltip.text"}/>
              <br/>
              <FormattedMessage id={"chart.symbol.size.tooltip.text"}/>
            </div>
          )}
        >
          <HelpOutlineIcon 
            style={{padding:0, margin:0, border:0}}
            // tooltip={}
          />
        </Tooltip>
      </Paper>
    </React.Fragment>
  );
}
