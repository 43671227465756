import React, { useState, useContext } from "react";
import axios from "axios";
import { FormattedMessage, useIntl } from "react-intl";
import { Paper, makeStyles, Button, Grid, Typography, Checkbox, Card, CardContent } from "@material-ui/core";
import ExploreIcon from "@material-ui/icons/Explore";
import RemoveIcon from "@material-ui/icons/Remove";
import DoneIcon from "@material-ui/icons/Done";
import MUIDataTable from "mui-datatables";

import AppContext from "../AppContext";

import styles from "../styles";
const useStyles = makeStyles(styles);

export default function ConfigurationDatalakeExportPreview() {
  const classes = useStyles();
  const context = useContext(AppContext);
  const intl = useIntl();

  const [allTableData, setAllTableData] = useState([]);
  const [statsOnExport, setStatsOnExport] = useState({ total: 0, exported: 0, excluded: 0, reduced: 0 });
  const [statsOnTags, setStatsOnTags] = useState([]);

  const columns = [
    {
      name: "metering_point_id",
      label: intl.formatMessage({ id: "smart_energi_datalake_export_preview.metering_point_id" }),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "reduced",
      label: intl.formatMessage({ id: "smart_energi_datalake_export_preview.reduced" }),
      options: {
        filter: true,
        customFilterListOptions: { render: (v) => `Reduced: ${v}` },
        filterOptions: {
          names: ["markerad", "inte markerad"],
          logic: (value, list) => {
            if (value === true && list.includes("markerad")) {
              return false;
            }
            if (value === false && list.includes("inte markerad")) {
              return false;
            }
            return true;
          },
        },
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Checkbox checked={value} color={"primary"} checkedIcon={<DoneIcon />} icon={<RemoveIcon />} />;
        },
      },
    },
    {
      name: "excluded",
      label: intl.formatMessage({ id: "smart_energi_datalake_export_preview.excluded" }),
      options: {
        filter: true,
        customFilterListOptions: { render: (v) => `Excluded: ${v}` },
        filterOptions: {
          names: ["markerad", "inte markerad"],
          logic: (value, list) => {
            if (value === true && list.includes("markerad")) {
              return false;
            }
            if (value === false && list.includes("inte markerad")) {
              return false;
            }
            return true;
          },
        },
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Checkbox checked={value} color={"primary"} checkedIcon={<DoneIcon />} icon={<RemoveIcon />} />;
        },
      },
    },
  ];
  const options = {
    downloadOptions: {
      filename: "Preview.csv",
      separator: ";",
    },
    filterType: "checkbox",
    responsive: "scrollMaxHeigh",
    elevation: 0,
    rowsPerPageOptions: [10, 20, 50],
    viewColumns: false,
    textLabels: intl.messages["MUIDataTables.textLabels"] || {},
    setTableProps: () => {return {size: 'small'}}
  };

  function checkAgainstRules(meteringPoint, listOfRules) {
    let excluded = false;
    let reduced = false;
    listOfRules.metering_point_tags.forEach((element) => {
      if ("tags" in meteringPoint) {
        meteringPoint.tags.forEach((meteringPointTags) => {
          if (element.enabled === false && element.metering_point_tag_key === meteringPointTags.key) {
            element.metering_point_tag_values.forEach((tag_value) => {
              if (meteringPointTags.val.includes(tag_value)) {
                excluded = true;
              }
            });
          }
          if (element.enabled === true && element.metering_point_tag_key === meteringPointTags.key) {
            element.metering_point_tag_values.forEach((tag_value) => {
              if (meteringPointTags.val.includes(tag_value)) {
                reduced = true;
              }
            });
          }
        });
      }
    });
    return [meteringPoint.metering_point_id, reduced, excluded];
  }

  function calculateStatsOfTags(meteringPoints, listOfRules) {
    let tag_info = [];
    listOfRules.metering_point_tags.forEach((element) => {
      let counter = 0;
      meteringPoints.forEach((meteringPoint) => {
        if ("tags" in meteringPoint) {
          meteringPoint.tags.forEach((tag) => {
            if (tag.key === element.metering_point_tag_key) {
              element.metering_point_tag_values.forEach((tagValue) => {
                tag.val.forEach((val) => {
                  if (val === tagValue) {
                    counter += 1;
                  }
                });
              });
            }
          });
        }
      });
      tag_info.push({ key: element.metering_point_tag_key, val: counter });
    });
    return tag_info;
  }

  function calculateStatsOfExport(list) {
    let tot = 0;
    let excluded = 0;
    let reduced = 0;
    list.forEach((element) => {
      tot += 1;
      if (element[1] === true) {
        reduced += 1;
      }
      if (element[2] === true) {
        excluded += 1;
      }
    });
    return { total: tot, excluded: excluded, reduced: reduced, exported: tot - excluded };
  }

  function calculateList(listOfMeteringPoints, listOfRules) {
    let list = [];
    listOfMeteringPoints.forEach((element) => {
      list.push(checkAgainstRules(element, listOfRules));
    });
    return list;
  }

  function updateTable(listOfMeteringPoints, listOfRules) {
    let list = calculateList(listOfMeteringPoints, listOfRules);
    setAllTableData(list);
    setStatsOnExport(calculateStatsOfExport(list));
    setStatsOnTags(calculateStatsOfTags(listOfMeteringPoints, listOfRules));
  }

  function fetchMeteringPoints(token) {
    var headers = { Authorization: "Bearer " + token };
    return axios.get(context.config.meteringPointsAPI + "/", { headers });
  }

  function fetchExportConfiguration(token) {
    var headers = { Authorization: "Bearer " + token };
    return axios.get(context.config.configurationAPI + "/export", { headers });
  }

  async function fetchAllMeteringPoints() {
    context.keycloak
      .updateToken(5)
      .success(function () {
        axios
          .all([fetchMeteringPoints(context.keycloak.token), fetchExportConfiguration(context.keycloak.token)])
          .then(
            axios.spread(function (meteringPointResponse, exportConfigurationResponse) {
              updateTable(meteringPointResponse.data, exportConfigurationResponse.data);
            })
          )
          .catch((error) => {
            console.log(error);
          });
      })
      .error(function () {
        context.keycloak.login();
      });
  }
  return (
    <React.Fragment>
      <Paper className={classes.Paper}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Typography variant="h6" color="textSecondary">
              <FormattedMessage id="smart_energi_datalake_export_preview.header" />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              startIcon={<ExploreIcon />}
              onClick={() => fetchAllMeteringPoints()}
            >
              <FormattedMessage id="smart_energi_datalake_export_preview.preview" />
            </Button>
          </Grid>
          <Grid item xs={6}>
            <MUIDataTable data={allTableData} columns={columns} options={options} />
          </Grid>
          <Grid item xs={4}>
            <Card>
              <CardContent>
                <Typography variant="h6" color="textSecondary">
                  <FormattedMessage id="smart_energi_datalake_export_preview.header.info" />
                </Typography>
                <Typography variant="subtitle1">
                  <FormattedMessage id="smart_energi_datalake_export_preview.total" /> {statsOnExport.total}
                </Typography>
                <Typography variant="subtitle1">
                  <FormattedMessage id="smart_energi_datalake_export_preview.exported" /> {statsOnExport.exported}
                </Typography>
                <Typography variant="subtitle1">
                  <FormattedMessage id="smart_energi_datalake_export_preview.reduced" /> {statsOnExport.reduced}
                </Typography>
                <Typography variant="subtitle1">
                  <FormattedMessage id="smart_energi_datalake_export_preview.excluded" /> {statsOnExport.excluded}
                </Typography>
                <br />
                <Typography variant="h6" color="textSecondary">
                  <FormattedMessage id="smart_energi_datalake_export_preview.header.per_tag" />
                </Typography>
                {statsOnTags.map((tag) => {
                  return (
                    <React.Fragment key={tag.key}>
                      <Typography variant="subtitle1" key="{tag.key}-{tag.val}">
                        {tag.key}: {tag.val}
                      </Typography>
                    </React.Fragment>
                  );
                })}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
