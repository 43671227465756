import React from "react";
import axios from "axios";
import { injectIntl } from "react-intl";
import CreatableSelect from "react-select/creatable";
import PropTypes from "prop-types";

import { withStyles, Grid, Tooltip, IconButton } from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import AddBoxIcon from "@material-ui/icons/AddBox";
import UndoIcon from "@material-ui/icons/Undo";
import DoneIcon from "@material-ui/icons/Done";

import styles from "../styles";

function createOptions(values) {
  var options = [];
  if (values) {
    for (var value of values) {
      options.push({
        value,
        label: value
      });
    }
  }
  return options;
}

function extractValues(options) {
  var values = [];
  if (options) {
    for (var option of options) {
      values.push(option.value);
    }
  }
  return values;
}

function arrayToDictionary(array) {
  var dictionary = {};
  if (array) {
    for (var item of array) {
      dictionary[item.key] = item.val;
    }
  }
  return dictionary;
}

function dictionaryToArray(dictionary) {
  var array = [];
  for (var key of Object.keys(dictionary)) {
    if (dictionary[key].length > 0) {
      array.push({
        key,
        val: dictionary[key]
      });
    }
  }
  return array;
}

class MeteringPointTags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      availableTags: {},
      tagsModified: false,
      createTagMenuOpen: false,
      tags: {}
    };
  }

  componentDidMount = () => {
    this.fetchAvailableTags();
    this.fetchTags();
  };

  fetchAvailableTags = () => {
    var thiz = this;
    this.props.context.keycloak
      .updateToken(5)
      .success(function() {
        var headers = {
          Authorization: "Bearer " + thiz.props.context.keycloak.token
        };
        axios
          .get(thiz.props.context.config.meteringPointsAPI + "/tags", {
            headers
          })
          .then(response => {
            var availableTags = {};
            for (var tag of response.data) {
              availableTags[tag.key] = tag.val;
            }
            thiz.setState({ availableTags });
          })
          .catch(error => {
            console.log(error);
          });
      })
      .error(function() {
        thiz.props.context.keycloak.login();
      });
  };

  fetchTags = () => {
    var thiz = this;
    this.props.context.keycloak
      .updateToken(5)
      .success(function() {
        var headers = {
          Authorization: "Bearer " + thiz.props.context.keycloak.token
        };
        var params = {
          fields: "tags"
        };
        axios
          .get(thiz.props.context.config.meteringPointsAPI + "/" + thiz.props.meteringPointId, {
            headers,
            params
          })
          .then(response => {
            thiz.setState({ tags: arrayToDictionary(response.data.tags) });
          })
          .catch(error => {
            console.log(error);
          });
      })
      .error(function() {
        thiz.props.context.keycloak.login();
      });
  };

  storeTags = () => {
    var thiz = this;
    this.props.context.keycloak
      .updateToken(5)
      .success(function() {
        var config = {
          method: "PUT",
          url: thiz.props.context.config.meteringPointsAPI + "/" + thiz.props.meteringPointId + "/tags",
          headers: {
            Authorization: "Bearer " + thiz.props.context.keycloak.token
          },
          data: dictionaryToArray(thiz.state.tags)
        };
        axios(config)
          .then(() => {
            // Do nothing
          })
          .catch(error => {
            console.log(error);
          });
      })
      .error(function() {
        thiz.props.context.keycloak.login();
      });
  };

  hasPrivileges = role => {
    if (
      (role === "admin" && this.props.context.keycloak.role === "admin") ||
      (role === "analyst" &&
        (this.props.context.keycloak.role === "analyst" || this.props.context.keycloak.role === "admin"))
    ) {
      return true;
    } else {
      return false;
    }
  };

  getUnusedTagKeys = () => {
    var unusedTags = [];
    for (var availableTagKey of Object.keys(this.state.availableTags)) {
      if (!this.state.tags[availableTagKey]) {
        unusedTags.push(availableTagKey);
      }
    }
    return unusedTags;
    
  };

  toggleCreateTagMenu = () => {
    this.setState({ createTagMenuOpen: !this.state.createTagMenuOpen });
  };

  handleAddNewTagKey = event => {
    var tags = this.state.tags;
    tags[event.value] = [];
    this.setState({ tags, tagsModified: true });
    this.toggleCreateTagMenu();
  };

  handleRemoveTagKey = tagKey => {
    var tags = this.state.tags;
    delete tags[tagKey];
    this.setState({ tags, tagsModified: true });
  };

  handleUpdateTags = (tagKey, tagValues) => {
    var tags = this.state.tags;
    tags[tagKey] = extractValues(tagValues);
    this.setState({ tags, tagsModified: true });
  };

  handleResetTags = () => {
    this.setState({
      tagsModified: false
    });
    this.fetchTags();
  };

  handleSaveTags = () => {
    this.storeTags();
    this.setState({
      tagsModified: false
    });
    if (this.props.handleFetchTags) {
      this.props.handleFetchTags(this.state.tags)
    }
    
  };

  render() {
    const { classes } = this.props;
    const tags = this.state.tags;

    if (this.props.compact) {
      return (
        <React.Fragment>
          <Grid container>
            <Grid item xs={12}>
              <div style={{maxHeight: 52, fontSize:12, textOverflow: "ellipsis", overflow: "hidden"}}>
                {this.state.tags
                  ? Object.keys(tags).map(tagKey => {
                    return (
                        <React.Fragment key={tagKey}>
                          <span style={{color:"rgba(0,0,0,0.54)"}}>{tagKey}:</span>
                          <b>{tags[tagKey].map(i => '['+i+']').join(',')}; </b>
                        </React.Fragment>
                    );
                  }) : null
                }
              </div>
            </Grid>
          </Grid>
          </React.Fragment>
      );
    }

    //Else, if not compact: return full view:
    return (
      <React.Fragment>
        {this.state.tags
          ? Object.keys(this.state.tags).map(tagKey => {
              return (
                <div key={tagKey}>
                  <div className={classes.TagKey}>{tagKey}</div>
                  <Grid container>
                    <Grid item xs={11}>
                      <CreatableSelect
                        className={classes.Select}
                        isMulti
                        isClearable={false}
                        isDisabled={!this.hasPrivileges("analyst")}
                        onChange={tagValues => this.handleUpdateTags(tagKey, tagValues)}
                        value={createOptions(this.state.tags[tagKey])}
                        options={createOptions(this.state.availableTags[tagKey])}
                        placeholder={this.props.intl.formatMessage({
                          id: "tags.select_or_create"
                        })}
                      />
                    </Grid>
                    <Grid item xs={1} className={classes.AlignRight}>
                      {this.hasPrivileges("analyst") ? (
                        <Tooltip
                          placement="top"
                          title={this.props.intl.formatMessage({
                            id: "delete"
                          })}
                        >
                          <IconButton onClick={() => this.handleRemoveTagKey(tagKey)}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                    </Grid>
                  </Grid>
                </div>
              );
            })
          : null}
        <div className={classes.TagKey}>&nbsp;</div>

        {this.hasPrivileges("analyst") ? (
          <Grid container>
            <Grid item xs={8}>
              {!this.state.createTagMenuOpen ? (
                <Tooltip
                  placement="top"
                  title={this.props.intl.formatMessage({
                    id: "tags.add_new"
                  })}
                >
                  <IconButton onClick={this.toggleCreateTagMenu}>
                    <AddBoxIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <CreatableSelect
                  options={createOptions(this.getUnusedTagKeys())}
                  menuIsOpen={true}
                  autoFocus={true}
                  onBlur={this.toggleCreateTagMenu}
                  onChange={this.handleAddNewTagKey}
                  placeholder={this.props.intl.formatMessage({
                    id: "tags.select_or_create"
                  })}
                />
              )}
            </Grid>
            <Grid item xs={4} className={classes.AlignRight}>
              {this.state.tagsModified ? (
                <React.Fragment>
                  <Tooltip
                    placement="top"
                    title={this.props.intl.formatMessage({
                      id: "cancel"
                    })}
                  >
                    <IconButton color="secondary" onClick={this.handleResetTags}>
                      <UndoIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    title={this.props.intl.formatMessage({
                      id: "save"
                    })}
                  >
                    <IconButton onClick={this.handleSaveTags}>
                      <DoneIcon />
                    </IconButton>
                  </Tooltip>
                </React.Fragment>
              ) : null}
            </Grid>
          </Grid>
        ) : null}
      </React.Fragment>
    );
  }
}

MeteringPointTags.propTypes = {
  context: PropTypes.object.isRequired,
  meteringPointId: PropTypes.string.isRequired
};

export default withStyles(styles)(injectIntl(MeteringPointTags));
