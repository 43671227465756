import React from "react";
import { FormattedMessage } from "react-intl";

import {
  withStyles,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Toolbar,
  Typography,
  Fab,
  Tooltip
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CloseIcon from "@material-ui/icons/Close";
import AddBoxIcon from "@material-ui/icons/AddBox";
import AddIcon from '@material-ui/icons/Add';

import styles from "../styles";
import {teal, lime, brown, deepOrange, blueGrey, grey, common} from '@material-ui/core/colors'
import MeteringPoint from "./MeteringPoint";
import MeteringPointAddLabelsDialog from "./MeteringPointAddLabelsDialog"
import ListOfChips from "./ListOFChips";

class MeteringPointLabels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noteText: "",
      expanded: false,
      openAddLabelDialog: false
    };
  }

  handleInput = event => {
    this.setState({ noteText: event.target.value });
  };

  toggleAddLabelsDialog = () => {
    this.setState({ openAddLabelDialog: !this.state.openAddLabelDialog });
  }


  handlePanelExpansion = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  formatTags = (label) => {
    if ("tags" in label) {
      let tags = []
      label.tags.forEach((tag) => {
        let key = tag.key;
        tag.val.forEach((value) => {
          tags.push(key + ":" + value);
        });
      });
      return <ListOfChips tags={tags} />;
    } else {
      return "";
    }
  };

  render() {
    const { expanded } = this.state;
    const { classes } = this.props;

    var labels;
    if (expanded) {
      labels = this.props.labels;
    } else {
      labels = this.props.labels.slice(0, 3);
    }

    return (
      <div>
        <Paper className={classes.Paper}>

          <Toolbar style={{padding:0}}>

            <Typography style={{flex: '90%', color: grey[700],fontSize:22, fontWeight:"inherit"}} variant="h6" id="tableTitle" component="div">
                  {this.props.intl.formatMessage({id: "metering_point.label_panel.title"})}
              </Typography>

              <div className={classes.AlignRight}>
                  <IconButton onClick={this.props.handleToggleShowLabels}>
                      <CloseIcon />
                  </IconButton>
              </div>
          </Toolbar>


          <Table>
            {labels.length > 0 ? (
              <React.Fragment>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.Width20Percent}>
                      {this.props.intl.formatMessage({id: "metering_points.labels_id"})}
                    </TableCell>
                    <TableCell className={classes.Width20Percent}>
                      {this.props.intl.formatMessage({id: "metering_points.labels_description"})}
                    </TableCell>
                    <TableCell className={classes.Width20Percent}>
                      {this.props.intl.formatMessage({id: "metering_points.labels_tags"})}
                    </TableCell>
                    <TableCell className={classes.Width20Percent}>
                      {this.props.intl.formatMessage({id: "metering_points.labels_creator"})}
                    </TableCell>
                    <TableCell className={classes.Width20Percent}>
                      {this.props.intl.formatMessage({id: "metering_points.labels_create_date"})}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {labels.map(label => (
                      <TableRow key={label.label_id}>
                        <TableCell>{label.label_id}</TableCell>
                        <TableCell>{label.description}</TableCell>
                        <TableCell>{this.formatTags(label)}</TableCell>
                        <TableCell>{label.creator}</TableCell>
                        <TableCell>{label.creation_time}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </React.Fragment>
            ) : null}
          </Table>
          
          <Grid container style={{marginTop:16}}>
            
              <Grid item xs={4}>
                  <Tooltip
                    title={this.props.intl.formatMessage({
                      id: "metering_points.add_new_label"
                    })}
                  >
                    <IconButton size="medium" onClick={this.toggleAddLabelsDialog}>
                        <AddBoxIcon/>
                    </IconButton>
                  </Tooltip>
              </Grid>
              
              <Grid item xs={4} className={classes.AlignCenter}>
              <IconButton
                  onClick={this.handleExpandClick}
                  aria-expanded={this.state.expanded}
                  aria-label="Show more"
              >
                  {!expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
              </Grid>
          </Grid>
        </Paper>
        <MeteringPointAddLabelsDialog
          open={this.state.openAddLabelDialog}
          toggleAddLabelsDialog={this.toggleAddLabelsDialog}
          context={this.props.context}
          meteringPointId={this.props.meteringPointId}
          creator={this.props.creator}
          handleCreateLabel={this.props.handleCreateLabel}
          isDataSelectedOnChart={this.props.isDataSelectedOnChart}
          selectedDataInfo={this.props.selectedDataInfo}
        />

      </div>
    );
  }
}

export default withStyles(styles)(MeteringPointLabels);
