import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";

import { Grid, Button, makeStyles } from "@material-ui/core";

import AppContext from "../AppContext";
import styles from "../styles";
const useStyles = makeStyles(styles);

export default function Login() {
  const classes = useStyles();
  const context = useContext(AppContext);

  if (context.config) {
    return (
      <Grid
        container
        spacing={8}
        direction="column"
        alignItems="center"
        justify="center"
        className={classes.LoginScreen}
      >
        <Grid item xs={3}>
          <img src={"/logo.png"} alt={"K2 Logo"} />
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={3}>
          <Button variant="outlined" onClick={context.keycloak.login}>
            <FormattedMessage id="login" />
          </Button>
        </Grid>
      </Grid>
    );
  } else {
    return <div />;
  }
}
