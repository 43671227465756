import React, {useState} from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import styles from "../styles";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";

import { withStyles, makeStyles, MenuItem, TextField, Grid, Dialog, Button,Table,
    TableBody,TableCell,TableHead,TableRow, Typography, IconButton } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from '@material-ui/icons/Close';
import {DeviationTable} from "./MeteringPointProtocolTable"

const useStyles = makeStyles(styles);
const protocol_lib = require("./ProtocolMenus");


const dialogTitleStyles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  
  const DialogTitle = withStyles(dialogTitleStyles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });


const CreateProtocolModal = (props) => {
    const classes = useStyles();
    var [create_protocol_component_options, setCreate_protocol_component_options] = useState([]);
    
    var [contains_validation_errors, setContains_validaiton_errors] = useState(true);
    
    var [create_protocol_job_id, setCreate_protocol_job_id] = useState('');
    var [job_id_error, setJob_id_error] = useState('');
    
    var [create_protocol_create_date, setCreate_protocol_create_date] = useState(new Date());
    var [create_date_error, setCreate_date_error] = useState('');
    
    var [create_protocol_selected_cause, setCreate_protocol_selected_cause] = useState('');
    var [cause_error, setCause_error] = useState('');

    var [create_protocol_selected_action, setCreate_protocol_selected_action] = useState('');
    var [action_error, setAction_error] = useState('');


    var [create_protocol_action_description, setCreate_protocol_action_description] = useState('');
    var [action_free_text_error, setAction_free_text_error] = useState('');
    
    
    var [create_protocol_selected_component, setCreate_protocol_selected_component] = useState('');
    var [component_error, setComponent_error] = useState('');
    var [create_protocol_component_description, setCreate_protocol_component_description] = useState('');
    var [component_free_text_error, setComponent_free_text_error] = useState('');
    
    var [create_protocol_selected_status, setCreate_protocol_selected_status] = useState('');
    var [status_error, setStatus_error] = useState('');


    var [create_protocol_full_description, setCreate_protocol_full_description] = useState('');
    var [create_protocol_disable_component_free_text, setCreate_protocol_disable_component_free_text] = useState(true);
    var [create_protocol_disable_action_free_text, setCreate_protocol_disable_action_free_text] = useState(true);
    
    
    var [create_protocol_disable_energy_usage_change, setCreate_protocol_disable_energy_usage_change] = useState(true);
         
    var [create_protocol_selected_energy_usage_change_options, setCreate_protocol_selected_energy_usage_change_options] = useState([]);    
    var [create_protocol_selected_energy_usage_change, setCreate_protocol_selected_energy_usage_change] = useState('');
    var [energy_usage_change_error, setEnergy_usage_change_error] = useState('');
    // is_form_valid has "" as initial value, sets to "n" if invalid, "y" if valid
    var [is_form_valid, setIs_form_valid] = useState("")

    const [deviations, setDeviations] = useState([]);

    const [deviationsSelected, setDeviationsSelected] = useState([]);

    const [totalDeviationsAdded, setTotalDeviationsAdded] = useState(0);

    const meteringPointId = props.meteringPointId;

    
            
    const updateComponentByCause = (event) => {
        var selectedCause = event.target.value;
        create_protocol_selected_cause = selectedCause
        setCreate_protocol_selected_cause(selectedCause);
        var newOptions = protocol_lib.cause_component_dic[selectedCause];
        

        // component
        if (newOptions)
        { 
          create_protocol_component_options=protocol_lib.cause_component_dic[selectedCause]  
          setCreate_protocol_component_options(protocol_lib.cause_component_dic[selectedCause]);
        } 
        else
        {
          create_protocol_component_options = []  
          setCreate_protocol_component_options([]);  
        }
        create_protocol_selected_component = ""
        setCreate_protocol_selected_component("");
        create_protocol_component_description = ""
        setCreate_protocol_component_description("");
        create_protocol_disable_component_free_text = true
        setCreate_protocol_disable_component_free_text(true)
        component_free_text_error = ""
        setComponent_free_text_error("")
        

        // ENERGY_USAGE_CHANGE
        if (selectedCause == "ENERGY_USAGE_CHANGE"){
          create_protocol_disable_energy_usage_change = false
          setCreate_protocol_disable_energy_usage_change(false);
          create_protocol_selected_energy_usage_change_options = protocol_lib.protocol_menus.ENERGY_USAGE_CHANGE        
          setCreate_protocol_selected_energy_usage_change_options(protocol_lib.protocol_menus.ENERGY_USAGE_CHANGE);
        }
        else
        {
          // disable all energy_usage_change related inputs
          create_protocol_disable_energy_usage_change = true
          setCreate_protocol_disable_energy_usage_change(true);
          create_protocol_selected_energy_usage_change_options = []
          setCreate_protocol_selected_energy_usage_change_options([]);
          create_protocol_selected_energy_usage_change = ""
          setCreate_protocol_selected_energy_usage_change("");
        }
        validate()

    };


  const addProtocol = (event) => {
    event.preventDefault();
    if (deviations.length == 0){
        alert("nothing to comit")
        return;
    }
    var datetime = new Date(Date.now())
    var protocolData =  {
        "creation_time": create_protocol_create_date.toISOString(), // Note: returns GMT standard time not swedish
        "creator": props.creator,
        "deviations":{},
        "job_id": deviations[0]['jobId'],
        "origin": "k2",
        "hidden": false,
        "disabled": false,

    }

    for(var i = 0; i < deviations.length; i ++){
        var d = deviations[i];
        protocolData["deviations"]["i_"+i.toString()]={
            "action": d.action,
            "action_other": d.action_other,
            "cause": d.cause,
            "component": d.component,
            "component_other": d.component_other,
            "energy_usage_change": d.energy_usage_change,
            "fault_description": d.fault_description,
            "status": d.status
        }
    }
        
    //console.log(protocolData)
    cleanState(true);
    props.handleAddProtocol(protocolData)
    props.handleToggleAddProtocolsDialog()
  }


  const validate = () => {
    var mandatory_error = props.intl.formatMessage({ id: "metering_point.create_protocol_mandatory" });
    var contains_error = false
    if (!create_protocol_job_id){
        setJob_id_error(mandatory_error)
        contains_error = true;
    } 
    else
    {
        setJob_id_error('')
    }

    if (!create_protocol_create_date){
        setCreate_date_error(mandatory_error)
        contains_error = true;
    } 
    else
    {
        setCreate_date_error('')
    }

    // cause
    if (!create_protocol_selected_cause){
        setCause_error(mandatory_error)
        contains_error = true;
    } 
    else
    {
        setCause_error('')
    }
    
    // energy usage change
    if (create_protocol_selected_cause == protocol_lib.ENERGY_USAGE_CHANGE
        && !create_protocol_selected_energy_usage_change)
    {
        setEnergy_usage_change_error(mandatory_error)
        contains_error = true
    }
    else
    {
        setEnergy_usage_change_error('')
    }

    // action
    if (!create_protocol_selected_action){
        setAction_error(mandatory_error)
        contains_error = true;
    } 
    else
    {
        setAction_error('')
    }

    // action freetext
    if (create_protocol_selected_action == protocol_lib.ACTION_OTHER
        && !create_protocol_action_description){
        setAction_free_text_error(mandatory_error)
        contains_error = true;
    } 
    else
    {
        setAction_free_text_error('')
    }

    // component
    if (create_protocol_selected_cause
        && create_protocol_selected_cause != protocol_lib.FAULT_DISTR_SYST
        && create_protocol_selected_cause != protocol_lib.ENERGY_USAGE_CHANGE
        && !create_protocol_selected_component){
        setComponent_error(mandatory_error)
        contains_error = true;
    } 
    else
    {
        setComponent_error('')
    }


    // component freetext
    if (create_protocol_selected_component == protocol_lib.COMP_OTHER
        && !create_protocol_component_description){
        setComponent_free_text_error(mandatory_error)
        contains_error = true;
    } 
    else
    {
        setComponent_free_text_error('')
    }


    // status
    if (!create_protocol_selected_status){
        setStatus_error(mandatory_error)
        contains_error = true;
    } 
    else
    {
        setStatus_error('')
    }

    setContains_validaiton_errors(contains_error)
    if (contains_error){
        setIs_form_valid("n")
    }
    else
    {
        setIs_form_valid("y")
    }
    return contains_error  

  }


  const addDeviation = (event) => {
    event.preventDefault();
    var contains_error = validate()
    if (contains_error){
        return
    }

    var deviation = {
        id: totalDeviationsAdded,
        status: create_protocol_selected_status,
        jobId: create_protocol_job_id,
        create_date: create_protocol_create_date,
        cause: create_protocol_selected_cause,
        action: create_protocol_selected_action,
        action_other: create_protocol_action_description,
        component: create_protocol_selected_component,
        component_other: create_protocol_component_description,
        fault_description: create_protocol_full_description,
        energy_usage_change: create_protocol_selected_energy_usage_change
      }
  
    var deviationsNewState = deviations.concat(deviation);
    // console.log(deviation)
    setDeviations(deviationsNewState);
    setTotalDeviationsAdded(prevCount=>prevCount+1);
    cleanState(false);
  }

  const cleanState = (fully_clean) => {
    setCreate_protocol_component_options([]);
    setCreate_protocol_selected_cause("");
    setCreate_protocol_selected_action("");
    setCreate_protocol_action_description("");
    setCreate_protocol_selected_component("");
    setCreate_protocol_component_description("");
    setCreate_protocol_selected_status("");
    setCreate_protocol_full_description("");
    setCreate_protocol_disable_component_free_text(true);
    setCreate_protocol_disable_action_free_text(true);
    setCreate_protocol_disable_energy_usage_change(true);
    setCreate_protocol_selected_energy_usage_change_options([]);
    setCreate_protocol_selected_energy_usage_change("");
    setJob_id_error("")
    setCreate_date_error("")
    setCause_error("")
    setAction_error("")
    setAction_free_text_error("")
    setComponent_error("")
    setComponent_free_text_error("")
    setStatus_error("")
    setEnergy_usage_change_error("")

    if (fully_clean){
        setDeviations([]);
        setCreate_protocol_job_id("")
    }
  }

  const closeDialog = (event) => {
    event.preventDefault();
    cleanState(true);
    props.handleToggleAddProtocolsDialog();
  }

  const handleDeleteDeviations = (id) => {
    if(deviationsSelected.includes(id)){
        setDeviationsSelected(deviationsSelected.filter((v,k)=> v!=id))
    }
    else{
        setDeviationsSelected(deviationsSelected.concat(id));
    }
  }

  const deleteDeviation = () => {
    setDeviations( prevDeviations => {
        return prevDeviations.filter((v,k)=> !deviationsSelected.includes(v.id))
    } );
    setDeviationsSelected([]);
  }

  const handleCreateDateChange = (value) => {
    setCreate_protocol_create_date(value)
  }

    return(
        
        <Dialog fullWidth={true} maxWidth="lg"
            open={props.open} onClose={props.handleToggleAddProtocolsDialog}>
            <DialogTitle id="customized-dialog-title" onClose={props.handleToggleAddProtocolsDialog}>
                {props.intl.formatMessage({ id: "metering_point.create_protocol.create" }) + " - " + props.meteringPointId}
            </DialogTitle>
            <MuiDialogContent>
            <Grid container>
                <Grid item xs={3}>
                    <TextField                
                        label={props.intl.formatMessage({
                        id: "metering_point.create_protocol_job_id.label",
                        })}
                        error={job_id_error}
                        helperText={job_id_error}
                        value={create_protocol_job_id}
                        onChange={(event)=>{
                            create_protocol_job_id = event.target.value;
                            setCreate_protocol_job_id(event.target.value);
                            validate();
                            
                        }}/>
                </Grid>
                <Grid item xs={9}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                        className={classes.DatePicker + " " + classes.MarginRight}
                        allowKeyboardControl
                        autoOk
                        disableFuture
                        label={props.intl.formatMessage({id: "metering_point.create_protocol_full_description.create_date"})}
                        InputLabelProps={{ shrink: true }}
                        format="YYYY-MM-DD"
                        onChange={(e) => handleCreateDateChange(e)}
                        value={create_protocol_create_date}
                        />
                    </MuiPickersUtilsProvider>

                </Grid>
                <Grid item xs={3}>
                    <TextField
                        className={classes.TextField}
                        id="create_protocol_deviation_cause"
                        select
                        label={props.intl.formatMessage({
                        id: "metering_point.create_protocol_deviation_cause.label",
                        })}
                        error={cause_error}
                        helperText={cause_error}
                        value={create_protocol_selected_cause}
                        onChange={updateComponentByCause}>
                        {protocol_lib.protocol_menus.CAUSE.map((cause) => (
                        <MenuItem key={cause} value={cause}>
                            <FormattedMessage id={"metering_point.create_protocol_deviation_cause_" + cause} />
                        </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        className={classes.TextField}
                        id="metering_point.create_protocol_energy_usage_change"
                        select
                        disabled={create_protocol_disable_energy_usage_change}
                        label={props.intl.formatMessage({
                            id: "metering_point.create_protocol_energy_usage_change.label",
                        })}
                        error={energy_usage_change_error}
                        helperText={energy_usage_change_error}
                        value={create_protocol_selected_energy_usage_change}
                        onChange={(event)=>{
                            create_protocol_selected_energy_usage_change = event.target.value;                        
                            setCreate_protocol_selected_energy_usage_change(event.target.value);
                            validate();
                        }}
                        defaultValue={""}>
                        {create_protocol_selected_energy_usage_change_options.map((x) => (
                        <MenuItem key={x} value={x}>
                            <FormattedMessage id={"metering_point.create_protocol_energy_usage_change_" + x} />
                        </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item xs={3}>
                    <TextField
                        className={classes.TextField}
                        id="create_protocol_component_select"
                        select
                        label={props.intl.formatMessage({
                        id: "metering_point.create_protocol_component.label",
                        })}
                        defaultValue={""}
                        error={component_error}
                        helperText={component_error}
                        disabled={create_protocol_component_options.length==0}
                        value={create_protocol_selected_component}
                        onChange={(event)=>{
                            create_protocol_selected_component = event.target.value;
                            setCreate_protocol_selected_component(event.target.value);
                            create_protocol_component_description = "";
                            setCreate_protocol_component_description("");
                            
                            if (event.target.value == "COMP_OTHER"){
                                create_protocol_disable_component_free_text = false
                                setCreate_protocol_disable_component_free_text(false);
                            } else
                            {
                                create_protocol_disable_component_free_text = true                                
                                setCreate_protocol_disable_component_free_text(true);
                            }
                            validate()
                        }}>
                        {create_protocol_component_options.map((comp) => (
                            <MenuItem key={comp} value={comp}>
                            <FormattedMessage id={"metering_point.create_protocol_" + comp} />                     
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={3}>
                    <TextField   
                        disabled={create_protocol_disable_component_free_text}           
                        label={props.intl.formatMessage({
                        id: "metering_point.create_protocol_component_description.label",
                        })}
                        id="metering_point.create_protocol_component_description"
                        value={create_protocol_component_description}
                        error={component_free_text_error}
                        helperText={component_free_text_error}
                        onChange={(event)=>{
                            create_protocol_component_description = event.target.value
                            setCreate_protocol_component_description(event.target.value)
                            validate()
                        }}/>
                </Grid>


                <Grid item xs={3}>
                    <TextField
                        className={classes.TextField}
                        id="create_protocol_action_select"
                        select
                        label={props.intl.formatMessage({
                        id: "metering_point.create_protocol_action.label",
                        })}
                        defaultValue={""}
                        error={action_error}
                        helperText={action_error}
                        value={create_protocol_selected_action}
                        onChange={(event)=>
                        {
                            create_protocol_selected_action = event.target.value;
                            setCreate_protocol_selected_action(event.target.value);
                            create_protocol_action_description = "";
                            setCreate_protocol_action_description("");
                            action_free_text_error=""
                            setAction_free_text_error("")
                            if (event.target.value=="ACTION_OTHER")
                            {
                                create_protocol_disable_action_free_text = false
                                setCreate_protocol_disable_action_free_text(false);
                            }
                            else
                            {
                                create_protocol_disable_action_free_text = true
                                setCreate_protocol_disable_action_free_text(true);
                            }
                            validate();
                            
                        }}>

                        {
                            protocol_lib.protocol_menus.ACTION.map((action) => (
                                <MenuItem key={action} value={action}>
                                    <FormattedMessage id={"metering_point.create_protocol_action_" + action} />
                                </MenuItem>
                            ))
                        }
                    </TextField>
                </Grid>
                <Grid item xs={3}>
                    <TextField                
                        label={props.intl.formatMessage({
                        id: "metering_point.create_protocol_action_description.label",
                        })}
                        id = "metering_point.create_protocol_action_description"
                        disabled={create_protocol_disable_action_free_text}
                        error={action_free_text_error}
                        helperText={action_free_text_error}
                        value={create_protocol_action_description}
                        onChange={(event)=>
                            {
                                create_protocol_action_description = event.target.value
                                setCreate_protocol_action_description(event.target.value)
                                validate()
                            }}/>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        className={classes.TextField}
                        id="create_protocol_status"
                        select
                        label={props.intl.formatMessage({
                        id: "metering_point.create_protocol_status.label",
                        })}
                        error={status_error}
                        helperText={status_error}
                        defaultValue={""}
                        value={create_protocol_selected_status}
                        onChange={(event)=>{
                            create_protocol_selected_status = event.target.value
                            setCreate_protocol_selected_status(event.target.value)
                            validate()
                        }}>
                        {protocol_lib.protocol_menus.STATUS.map((status) => (
                        <MenuItem key={status} value={status}>
                            <FormattedMessage id={"metering_point.create_protocol_" + status} />
                        </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                
                <Grid item xs={12}>
                    <TextField
                        style={{"margin-top":"20px"}}
                        fullWidth="true"
                        id="create_protocol_full_description"
                        label={props.intl.formatMessage({
                                id: "metering_point.create_protocol_full_description.label",
                            })}
                        multiline
                        rows={4}
                        value={create_protocol_full_description}
                        onChange={(event)=>{
                            create_protocol_full_description = event.target.value                        
                            setCreate_protocol_full_description(event.target.value)
                            validate()
                        }}
                        variant="outlined"/>
                </Grid>
            </Grid>
            
            <br/>
            

            {
                deviations.length>0?
                <DeviationTable
                    {...props}
                    deviations={deviations}
                    deleteDeviation={handleDeleteDeviations}
                />
                :null
            }
            
            </MuiDialogContent>
            <MuiDialogActions>
                <Button variant="contained" onClick={addDeviation} color="primary" disabled={is_form_valid!="y"}>
                    {props.intl.formatMessage({ id: "metering_point.create_protocol.create_deviation" })}
                </Button>
                <Button variant="contained" onClick={deleteDeviation} color="secondary" disabled={deviationsSelected.length == 0}>
                    {props.intl.formatMessage({ id: "metering_point.create_protocol.delete_deviation"}) + " ("+String(deviationsSelected.length)+")"}
                </Button>
                <Button variant="contained" onClick={addProtocol} color="primary" disabled={deviations.length == 0}>
                    {props.intl.formatMessage({ id: "metering_point.create_protocol.create" })}
                </Button>
                {/* <Button variant="contained" onClick={closeDialog} color="secondary">
                    {props.intl.formatMessage({ id: "metering_point.create_protocol.close" })}
                </Button> */}
            </MuiDialogActions>
        </Dialog>
        
    );

};


export default withStyles(styles)(injectIntl(CreateProtocolModal));