import React from "react";
//import Plot from "react-plotly.js";
import CloseIcon from "@material-ui/icons/Close";
import { injectIntl } from "react-intl";
import styles from "../styles";
import { withStyles, IconButton, Dialog, DialogActions, DialogContent } from "@material-ui/core";

//Lines below have to be last
import createPlotlyComponent from "react-plotly.js/factory";
import Plotly from "plotly.js-basic-dist-min";
const Plot = createPlotlyComponent(Plotly);

class ChartDSignature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.getData(),
      layout: this.getLayout(),
      modalDiagram: false,
      configPlot: { 
        responsive: true,
        modeBarButtonsToAdd: [
          {
            // name: this.props.intl.formatMessage({id: "chart.zoom"}),
            name: "Magnify",
            icon: {
              "name": this.props.intl.formatMessage({id: "chart.zoom"}),
              "svg": '<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" '
                      +'height="24" viewBox="0 0 24 24" width="24">'
                      +'<g><rect fill="none" height="24" width="24"/></g><g><g><g>'
                      +'<path d="M15,3l2.3,2.3l-2.89,2.87l1.42,1.42L18.7,6.7L21,9V3H15z '
                      +'M3,9l2.3-2.3l2.87,2.89l1.42-1.42L6.7,5.3L9,3H3V9z '
                      +'M9,21 l-2.3-2.3l2.89-2.87l-1.42-1.42L5.3,17.3L3,15v6H9z '
                      +'M21,15l-2.3,2.3l-2.87-2.89l-1.42,1.42l2.89,2.87L15,21h6V15z"/>'
                      +'</g></g></g></svg>'
            },
            click: this.openDialog
          }
        ],
      },
      revision: 0
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.separateCalculated !== this.props.separateCalculated
        || prevProps.data !== this.props.data) {
      this.convertDataToXY();
    } else if (prevProps.markedLength !== this.props.markedLength
        || (typeof this.props.markedStart === "object"
        && prevProps.markedStart !== this.props.markedStart) ) {
      if (!this.props.waitingForData) {
        this.updateMarkedData();
      }
    }
    if (this.props.chartHoverLabel !== prevProps.chartHoverLabel) {
      this.setState({layout: this.getLayout()})
    }
  }

  closeDialog = () => {
    this.setState({ modalDiagram: false });
  };

  openDialog = () => {
    this.setState({ modalDiagram: true });
  };

  getLayout = () => {
    const xLabel = this.props.intl.formatMessage({
      id: "chart.temperature_outdoor_daily_average"
    });
    const yLabelL = this.props.intl.formatMessage({
      id: "chart.temperature.y"
    });

    return {
      title: {
        text: this.props.intl.formatMessage({ id: this.props.title }),
        y: 1,
        x: 0.5,
        xanchor: "center",
        yanchor: "top",
        pad: {t: 8}
      },
      margin: { l: 50, r: 50, t: 10, b: 10, pad: 10 },
      legend: { orientation: "h" },
      xaxis: { title: xLabel, titlefont: { size: 9 }, zeroline: this.props.zerolines },
      yaxis: { title: yLabelL, titlefont: { size: 9 },
        range: [0, this.props.maxTempLimit], zeroline: this.props.zerolines
      },
      plot_bgcolor: this.props.colorPattern.bgcolor,
      paper_bgcolor: this.props.colorPattern.papercolor,
      hovermode: (this.props.chartHoverLabel === false) ? false : 'closest',
      hoverdistance: 1
    };
  };

  getData = () => {
    return [
      {
        x: [1e-9, 1e-8],
        y: [1e-9, 1e-8],
        mode: "markers",
        marker: { color: this.props.colorPattern["bgcolor"] },
        text: ['',''],
      }
    ];
  };

  updateMarkedData = () => {
    let groupMarked = this.state.data[
      this.state.data.findIndex((x) => x.name === this.props.intl.formatMessage({ id: "chart.marked" }))
    ];
    if (this.props.markedLength > 0) {
      let newGroup = false;
      if (typeof groupMarked === "undefined") {
        let color = this.props.colorPattern["marked"];
        groupMarked = {
          type: "scatter",
          mode: "markers",
          name: this.props.intl.formatMessage({ id: "chart.marked" }),
          marker: { 
            color: color,
            symbol: this.props.chartSymbol,
            size: this.props.chartSymbolSize,
            line: {color: this.props.colorPattern["marked_2"], width: 1}
          },
          hovertemplate: this.props.intl.formatMessage({id: "chart.outdoor_temperature"})
              +': %{x} °C'
        };
        newGroup = true;
      }
      let markedPoints = [];
      let x = [];
      let y = [];
      let text = [];

      this.props.data.sort((a, b) => {
        return new Date(a.timestamp) - new Date(b.timestamp);
      }).forEach((meterValue, index) => {
        if (
          new Date(meterValue.timestamp).getTime() >= this.props.markedStart.getTime() &&
          new Date(meterValue.timestamp).getTime() <= this.props.markedEnd.getTime()
        ) {

          if (meterValue.temperatures) {
            // meterValue.temperatures.forEach((value, key, index) => {
            for (const key in meterValue.temperatures) {
              markedPoints.push({
                x: this.roundValue(meterValue.outdoor_temperature, 3),
                y: this.roundValue(meterValue.temperatures[key], 3),
                text: new Date(meterValue.timestamp)
              })
            }
          }
        }
      });

      markedPoints.sort((p1,p2) => (p1.text < p2.text ? -1 : 1))
      for (const mp of markedPoints) {
        x.push(mp.x);
        y.push(mp.y);
        text.push(mp.text);
      }

      groupMarked["x"] = x;
      groupMarked["y"] = y;
      groupMarked["text"] = text;
      let data = this.state.data;
      if (newGroup) {
        data.push(groupMarked);
      }
      this.setState({ data, revision: this.state.revision+1 });
    } else {  //else if (this.props.markedLength == 0)
      if (typeof groupMarked !== "undefined") {
        //Remove marked group
        let data = this.state.data;
        data.pop();
        this.setState({ data, revision: this.state.revision+1 });
      }
    }
  };

  /**
   *
   * @param {int} value The number to be rounded
   * @param {int} number_of_decimals How many decimals to round the number to
   */
  roundValue = (value, number_of_decimals) => {
    try {
      return +value.toFixed(number_of_decimals);
    } catch (error) {
      return null;
    }
  };

  convertDataToXY = () => {
    let data = [];
    let group_flow = {};
    let group_return = {};
    let group_delta = {};
    let color_flow = this.props.colorPattern["temp_flow"];
    let color_return = this.props.colorPattern["temp_return"];
    let color_delta = this.props.colorPattern["temp_delta"];
    group_flow = {
      type: "scatter",
      mode: "markers",
      name: this.props.intl.formatMessage({ id: "chart.flow_temperature" }),
      marker: { color: color_flow, symbol: this.props.chartSymbol, size: this.props.chartSymbolSize },
      zeroline: false,
      hovertemplate: this.props.intl.formatMessage({ id: "chart.flow_temperature" })
          +': %{customdata[0]} °C<br>'
          + this.props.intl.formatMessage({id: "chart.outdoor_temperature"})
          + ': %{x} °C<br>%{customdata[1]|%Y-%m-%d %H:%M}'
    };
    group_return = {
      type: "scatter",
      mode: "markers",
      name: this.props.intl.formatMessage({ id: "chart.return_temperature" }),
      marker: { color: color_return, symbol: this.props.chartSymbol, size: this.props.chartSymbolSize },
      zeroline: false,
      hovertemplate: this.props.intl.formatMessage({ id: "chart.return_temperature" })
        +': %{customdata[0]} °C<br>'
        + this.props.intl.formatMessage({id: "chart.outdoor_temperature"})
        + ': %{x} °C<br>%{customdata[1]|%Y-%m-%d %H:%M}'
    };
    group_delta = {
      type: "scatter",
      mode: "markers",
      name: this.props.intl.formatMessage({
        id: "chart.temperature_difference"
      }),
      marker: { color: color_delta, symbol: this.props.chartSymbol, size: this.props.chartSymbolSize },
      zeroline: false,
      hovertemplate: this.props.intl.formatMessage({id: "chart.temperature_difference"})
        +': %{customdata[0]} °C<br>'
        + this.props.intl.formatMessage({id: "chart.outdoor_temperature"})
        + ': %{x} °C<br>%{customdata[1]|%Y-%m-%d %H:%M}'
    };
    let x = [];
    let y_flow = [];
    let y_return = [];
    let y_delta = [];
    let text = [];
    let y_flow_customdata = [];
    let y_return_customdata = [];
    let y_delta_customdata = [];
    if (typeof this.props.data === "undefined") return;
    this.props.data
      .sort((a, b) => {
        return new Date(a.timestamp) - new Date(b.timestamp);
      })
      .forEach((element) => {
        x.push(this.roundValue(element.outdoor_temperature, 3));
        text.push(new Date(element.timestamp));

        let flow_temp = this.roundValue(element.temperatures.flow_value, 3);
        let return_temp = this.roundValue(element.temperatures.return_value, 3);
        let delta_temp = this.roundValue(element.temperatures.difference_value, 3)

        y_flow.push( (flow_temp > this.props.maxTempLimit) ? this.props.maxTempLimit : flow_temp );
        y_return.push( (return_temp > this.props.maxTempLimit) ? this.props.maxTempLimit : return_temp );
        y_delta.push( (delta_temp > this.props.maxTempLimit) ? this.props.maxTempLimit : delta_temp );

        y_flow_customdata.push([flow_temp, element.timestamp]);
        y_return_customdata.push([return_temp, element.timestamp]);
        y_delta_customdata.push([delta_temp, element.timestamp]);
      }
    );
    group_flow["x"] = x;
    group_flow["y"] = y_flow;
    group_flow["text"] = text;
    group_flow["customdata"] = y_flow_customdata;
    group_return["x"] = x;
    group_return["y"] = y_return;
    group_return["text"] = text;
    group_return["customdata"] = y_return_customdata;
    group_delta["x"] = x;
    group_delta["y"] = y_delta;
    group_delta["text"] = text;
    group_delta["customdata"] = y_delta_customdata;
    data.push(group_flow);
    data.push(group_return);
    data.push(group_delta);
    this.setState({ data }, this.updateMarkedData);
  };

  render() {
    const { classes } = this.props;
    const title = this.props.title + "_signature";
    return (
      <React.Fragment>
        <Dialog
          open={this.state.modalDiagram}
          onClose={this.closeDialog}
          aria-labelledby="modal-diagram-dialog-title"
          aria-describedby="modal-diagram-description"
          fullWidth
          maxWidth={false}
        >
          <div>
            <DialogActions>
              <IconButton onClick={this.closeDialog}>
                <CloseIcon />
              </IconButton>
            </DialogActions>
            <DialogContent className={classes.p90Height}>
              <Plot
                data={this.state.data}
                layout={this.state.layout}
                config={this.state.configPlot}
                useResizeHandler={true}
                style={{ width: "100%", height: "792px" }}
              />
            </DialogContent>
          </div>
        </Dialog>
        <Plot
          divId={title}
          data={this.state.data}
          layout={this.state.layout}
          config={this.state.configPlot}
          revision={this.state.revision}
          useResizeHandler={true}
          style={{ width: "100%", height: this.props.chartHeight }}
          onHover={p => this.props.pOnHover(title, p, true)}
          onUnhover={p => this.props.pOnHover(title, p, false)}
          onRelayout={r => this.props.pOnRelayout(title, r)}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(injectIntl(ChartDSignature));
