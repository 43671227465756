import React, {memo, useEffect, useState} from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import CreateProtocolModal from "./CreateProtocolModal";
import ProtocolTable from "./MeteringPointProtocolTable"

import {
  withStyles,
  Grid,
  Paper,
  IconButton,
  makeStyles,
  Typography,
  Toolbar,
  Tooltip
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import AddBoxIcon from '@material-ui/icons/AddBox';
import CloseIcon from "@material-ui/icons/Close";


import styles from "../styles";
import {grey, common} from '@material-ui/core/colors'



const useStyles = makeStyles(styles);


const MeteringPointProtocols = memo((props) =>{
    const classes = useStyles();
    const [openAddProtocolDialog, setOpenAddProtocolDialog] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () =>{
        setExpanded(!expanded);
    }
    const handleToggleAddProtocolsDialog = () => {
        setOpenAddProtocolDialog(!openAddProtocolDialog);
    }

    const handleAddProtocol = (newProtocol) => {
        props.handleAddProtocol(newProtocol);
    }

    var protocols = [];
    if (props.protocols) {
        if (expanded) {
            protocols = props.protocols;
        } else {
            protocols = props.protocols.slice(0, 2);
        }
    }
   

    return (
        <>
            <Paper elevation={2} className={classes.Paper}>

                <Toolbar style={{padding:0}}>
                    <Typography style={{flex: '90%', color: grey[700],fontSize:22, fontWeight:"inherit"}} variant="h6" id="tableTitle" component="div">
                        {props.intl.formatMessage({id: "metering_point.protocol_panel.title"})}
                    </Typography>

                    <div className={classes.AlignRight}>
                        <IconButton onClick={props.handleToggleShowProtocols}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Toolbar>

                <ProtocolTable
                  protocols={protocols}
                  handleViewInGraph={props.handleViewProtocolsInGraph}
                />
             
                <Grid container style={{marginTop:16}}>
                    <Grid item xs={4}>
                        <Tooltip
                            title={props.intl.formatMessage({
                            id: "metering_point.create_protocol.tooltips"
                            })}
                        >
                            <IconButton size="medium" onClick={handleToggleAddProtocolsDialog}>
                                <AddBoxIcon/>
                            </IconButton>
                        </Tooltip>
                       
                    </Grid>
                    
                    <Grid item xs={4} className={classes.AlignCenter}>
                    <IconButton
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="Show more"
                    >
                        {!expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                    </IconButton>
                    </Grid>
                </Grid>
            </Paper>
            <CreateProtocolModal 
                open={openAddProtocolDialog}
                handleToggleAddProtocolsDialog={handleToggleAddProtocolsDialog}
                meteringPointId={props.meteringPointId}
                handleAddProtocol={handleAddProtocol}
                creator={props.creator}
            />
        </>

    );
});

MeteringPointProtocols.propTypes = {
    protocols: PropTypes.array.isRequired,
    meteringPointId: PropTypes.string.isRequired,
    handleToggleShowProtocols: PropTypes.func.isRequired,
    handleShowCreateProtocolsDialog: PropTypes.func.isRequired,
    handleAddProtocol: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(MeteringPointProtocols));
