import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import axios from "axios";
import { FormattedMessage, useIntl } from "react-intl";
import { Paper, makeStyles, Switch, IconButton, Tooltip, Grid, Typography, Divider } from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteIcon from "@material-ui/icons/Delete";
import UndoIcon from "@material-ui/icons/Undo";
import DoneIcon from "@material-ui/icons/Done";
import classNames from "classnames";

import AppContext from "../AppContext";

import styles from "../styles";
const useStyles = makeStyles(styles);

function createOptions(values) {
  let options = [];
  if (values) {
    for (let value of values) {
      options.push({
        value,
        label: value
      });
    }
  }
  return options;
}

const METERING_POINT_FIELDS = [
  "annual_consumption",
  "location.address.city",
  "location.address.postal_code",
  "location.address.street_address",
  "location.position",
  "manufacturer",
  "meter_id",
  "model",
  "type"
];

export default function ConfigurationDatalakeExport() {
  const classes = useStyles();
  const context = useContext(AppContext);
  const intl = useIntl();

  const [allTags, setAllTags] = useState({});
  const [defaultConfiguration, setDefaultConfiguration] = useState();
  const [tagConfigurations, setTagConfigurations] = useState();
  const [addTagMenuOpen, setAddTagMenuOpen] = useState(false);
  const [configurationModified, setConfigurationModified] = useState(false);

  function fetchExportConfiguration(token) {
    var headers = { Authorization: "Bearer " + token };
    return axios.get(context.config.configurationAPI + "/export", { headers });
  }

  function fetchAllTags(token) {
    var headers = { Authorization: "Bearer " + token };
    return axios.get(context.config.meteringPointsAPI + "/tags", { headers });
  }

  async function fetchConfiguration() {
    setConfigurationModified(false);
    context.keycloak
      .updateToken(5)
      .success(function() {
        axios
          .all([fetchAllTags(context.keycloak.token), fetchExportConfiguration(context.keycloak.token)])
          .then(
            axios.spread(function(availableTagsResponse, exportConfigurationResponse) {
              let allTags = {};
              for (let tag of availableTagsResponse.data) {
                allTags[tag.key] = tag.val;
              }
              setAllTags(allTags);

              let configuration = exportConfigurationResponse.data;

              setDefaultConfiguration({
                default_enabled: configuration.default_enabled,
                default_excluded_metering_point_fields: configuration.default_excluded_metering_point_fields
              });

              let updatedTagConfigurations = {};
              for (let metering_point_tag of configuration.metering_point_tags) {
                let tagConfiguration = {
                  metering_point_tag_key: metering_point_tag.metering_point_tag_key,
                  metering_point_tag_values: metering_point_tag.metering_point_tag_values,
                  enabled: metering_point_tag.enabled,
                  excluded_metering_point_fields: metering_point_tag.excluded_metering_point_fields
                };
                updatedTagConfigurations[metering_point_tag.metering_point_tag_key] = tagConfiguration;
              }
              setTagConfigurations(updatedTagConfigurations);
            })
          )
          .catch(error => {
            console.log(error);
          });
      })
      .error(function() {
        context.keycloak.login();
      });
  }

  async function saveExportConfiguration() {
    setConfigurationModified(false);
    let exportConfiguration = {
      default_enabled: defaultConfiguration.default_enabled,
      default_excluded_metering_point_fields: defaultConfiguration.default_excluded_metering_point_fields,
      metering_point_tags: Object.values(tagConfigurations)
    };

    context.keycloak
      .updateToken(5)
      .success(function() {
        let headers = { Authorization: "Bearer " + context.keycloak.token };
        axios.put(context.config.configurationAPI + "/export", exportConfiguration, { headers }).catch(error => {
          console.log(error);
        });
      })
      .error(function() {
        context.keycloak.login();
      });
  }

  useEffect(() => {
    fetchConfiguration();
  }, []);

  function getUnusedTags() {
    return Object.keys(allTags).filter(tag => !(tag in tagConfigurations));
  }

  function updateSelectedTagValues(metering_point_tag_key, metering_point_tag_values) {
    let updatedTagConfigurations = Object.assign({}, tagConfigurations);
    let tagConfiguration = updatedTagConfigurations[metering_point_tag_key];
    if (metering_point_tag_values) {
      tagConfiguration.metering_point_tag_values = metering_point_tag_values.map(value => value.value);
    } else {
      tagConfiguration.metering_point_tag_values = [];
    }
    updatedTagConfigurations[metering_point_tag_key] = tagConfiguration;
    setTagConfigurations(updatedTagConfigurations);
    setConfigurationModified(true);
  }

  function updateTagExcludedMeteringPointFields(metering_point_tag_key, excluded_metering_point_fields) {
    let updatedTagConfigurations = Object.assign({}, tagConfigurations);
    let tagConfiguration = updatedTagConfigurations[metering_point_tag_key];
    if (excluded_metering_point_fields) {
      tagConfiguration.excluded_metering_point_fields = excluded_metering_point_fields.map(value => value.value);
    } else {
      tagConfiguration.excluded_metering_point_fields = [];
    }
    updatedTagConfigurations[metering_point_tag_key] = tagConfiguration;
    setTagConfigurations(updatedTagConfigurations);
    setConfigurationModified(true);
  }

  function updateDefaultExcludedMeteringPointFields(excluded_metering_point_fields) {
    let updatedDefaultConfiguration = Object.assign({}, defaultConfiguration);
    if (excluded_metering_point_fields) {
      updatedDefaultConfiguration.excluded_metering_point_fields = excluded_metering_point_fields.map(
        value => value.value
      );
    } else {
      updatedDefaultConfiguration.excluded_metering_point_fields = [];
    }
    setDefaultConfiguration(updatedDefaultConfiguration);
    setConfigurationModified(true);
  }

  function toggleDefaultExportEnabled() {
    let updatedDefaultConfiguration = Object.assign({}, defaultConfiguration);
    updatedDefaultConfiguration.default_enabled = !updatedDefaultConfiguration.default_enabled;
    setDefaultConfiguration(updatedDefaultConfiguration);
    setConfigurationModified(true);
  }

  function toggleTagExportEnabled(metering_point_tag_key) {
    let updatedTagConfigurations = Object.assign({}, tagConfigurations);
    let tagConfiguration = updatedTagConfigurations[metering_point_tag_key];
    tagConfiguration.enabled = !tagConfiguration.enabled;
    updatedTagConfigurations[metering_point_tag_key] = tagConfiguration;
    setTagConfigurations(updatedTagConfigurations);
    setConfigurationModified(true);
  }

  function removeConfiguration(metering_point_tag_key) {
    let updatedTagConfigurations = Object.assign({}, tagConfigurations);
    delete updatedTagConfigurations[metering_point_tag_key];
    setTagConfigurations(updatedTagConfigurations);
    setConfigurationModified(true);
  }

  function addTagConfiguration(metering_point_tag_key) {
    let updatedTagConfigurations = Object.assign({}, tagConfigurations);

    updatedTagConfigurations[metering_point_tag_key] = {
      enabled: true,
      excluded_metering_point_fields: [],
      metering_point_tag_key: metering_point_tag_key,
      metering_point_tag_values: []
    };
    setTagConfigurations(updatedTagConfigurations);
    setAddTagMenuOpen(false);
    setConfigurationModified(true);
  }

  if (tagConfigurations) {
    return (
      <React.Fragment>
        <Paper className={classes.Paper}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6" color="textSecondary">
                <FormattedMessage id="smart_energi_datalake_export" />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <div className={classes.TagKey}>
                    <FormattedMessage id="default" />
                  </div>
                </Grid>
                <Grid item xs={9}>
                  <div className={classes.TagKey}>
                    <FormattedMessage id="smart_energi_datalake_export.excluded_metering_point_fields" />
                  </div>
                </Grid>

                <Grid item xs={3}>
                  <Select className={classes.Select} isDisabled placeholder={intl.formatMessage({ id: "default" })} />
                </Grid>
                <Grid item xs={3}>
                  <Select
                    className={classes.Select}
                    isMulti
                    placeholder={intl.formatMessage({
                      id: "smart_energi_datalake_export.select_excluded_metering_point_fields_placeholder"
                    })}
                    isClearable={false}
                    options={createOptions(METERING_POINT_FIELDS)}
                    value={createOptions(defaultConfiguration.excluded_metering_point_fields)}
                    onChange={updateDefaultExcludedMeteringPointFields}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Tooltip
                    placement="top"
                    title={
                      defaultConfiguration.default_enabled
                        ? intl.formatMessage({ id: "smart_energi_datalake_export.disable" })
                        : intl.formatMessage({ id: "smart_energi_datalake_export.enable" })
                    }
                  >
                    <Switch
                      checked={defaultConfiguration.default_enabled}
                      onChange={toggleDefaultExportEnabled}
                      color="primary"
                    />
                  </Tooltip>
                </Grid>
              </Grid>

              {Object.values(tagConfigurations).map(tagConfiguration => {
                return (
                  <Grid container spacing={1} key={tagConfiguration.metering_point_tag_key}>
                    <Grid item xs={6}>
                      <Divider className={classNames(classes.MarginTop, classes.MarginBottom)} />
                    </Grid>
                    <Grid item xs={6} />
                    <Grid item xs={3}>
                      <div className={classes.TagKey}>{tagConfiguration.metering_point_tag_key}</div>
                    </Grid>
                    <Grid item xs={9}>
                      <div className={classes.TagKey}>
                        <FormattedMessage id="smart_energi_datalake_export.excluded_metering_point_fields" />
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <Select
                        className={classes.Select}
                        isMulti
                        placeholder={intl.formatMessage({
                          id: "smart_energi_datalake_export.select_tag_value_placeholder"
                        })}
                        isClearable={false}
                        options={createOptions(allTags[tagConfiguration.metering_point_tag_key])}
                        value={createOptions(tagConfiguration.metering_point_tag_values)}
                        onChange={values => updateSelectedTagValues(tagConfiguration.metering_point_tag_key, values)}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Select
                        className={classes.Select}
                        isMulti
                        placeholder={intl.formatMessage({
                          id: "smart_energi_datalake_export.select_excluded_metering_point_fields_placeholder"
                        })}
                        isClearable={false}
                        options={createOptions(METERING_POINT_FIELDS)}
                        value={createOptions(tagConfiguration.excluded_metering_point_fields)}
                        onChange={values =>
                          updateTagExcludedMeteringPointFields(tagConfiguration.metering_point_tag_key, values)
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Tooltip
                        placement="top"
                        title={
                          tagConfiguration.enabled
                            ? intl.formatMessage({ id: "smart_energi_datalake_export.disable" })
                            : intl.formatMessage({ id: "smart_energi_datalake_export.enable" })
                        }
                      >
                        <Switch
                          checked={tagConfiguration.enabled}
                          onChange={() => toggleTagExportEnabled(tagConfiguration.metering_point_tag_key)}
                          color="primary"
                        />
                      </Tooltip>
                      <Tooltip placement="top" title={intl.formatMessage({ id: "delete" })}>
                        <IconButton onClick={() => removeConfiguration(tagConfiguration.metering_point_tag_key)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                );
              })}
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  {addTagMenuOpen ? (
                    <Select
                      options={createOptions(getUnusedTags())}
                      menuIsOpen={true}
                      autoFocus={true}
                      onBlur={() => setAddTagMenuOpen(false)}
                      onChange={value => addTagConfiguration(value.value)}
                    />
                  ) : (
                    <Tooltip placement="top" title={intl.formatMessage({ id: "tags.add_new" })}>
                      <IconButton onClick={() => setAddTagMenuOpen(true)}>
                        <AddBoxIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item xs={3} align="right">
                  {configurationModified && (
                    <React.Fragment>
                      <Tooltip placement="top" title={intl.formatMessage({ id: "cancel" })}>
                        <IconButton onClick={fetchConfiguration}>
                          <UndoIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip placement="top" title={intl.formatMessage({ id: "apply" })}>
                        <IconButton onClick={saveExportConfiguration}>
                          <DoneIcon />
                        </IconButton>
                      </Tooltip>
                    </React.Fragment>
                  )}
                </Grid>
                <Grid item xs={6} />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </React.Fragment>
    );
  } else {
    return null;
  }
}
