import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import axios from "axios";
import { withRouter } from "react-router-dom";

import { withStyles, Grid, Input, Typography, IconButton, Tooltip } from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";

import MeteringPointInformation from "./MeteringPointInformation";
import MeteringPointNotes from "./MeteringPointNotes";
import MeteringPointProtocols from "./MeteringPointProtocols"
import MeteringPointLabels from "./MeteringPointLabels"

import styles from "../styles";
import Charts from "./Charts";
import ErrorMessage from "./ErrorMessage";
import { isThisSecond } from "date-fns";

/**
 * This class handle metering point information
 */
class MeteringPoint extends React.Component {
  meteringPointId = null;

  constructor(props) {
    super(props);

    if (props.meteringPointId) {
      this.meteringPointId = props.meteringPointId;
    }
    this.updateDiagrams = false;
    this.state = {
      meteringPoint: null,
      showNotes: false,
      showProtocols: false,
      showLabels: false,
      isDataSelectedOnChart:false,
      selectedDataInfo:{},
      showCreateProtocolsDialog: false,
      protocolTimestampsViewList: [],
      meteringPointProtocols: null,
      meteringPointLabels: [],
      errorMessage: null,
      errorPopupMessage: "",
      errorOpen: false,
      searchedMeteringPoint: null,
      previewRefPeriod: null,
      alarmsPeriod: (this.props.location && this.props.location.state) ?
          this.props.location.state.alarmsPeriod : -1
    };
  }

  componentDidMount() {
    if (this.meteringPointId) {
      this.fetchMeteringPoint();
      this.fetchMeteringPointProtocols();
      this.fetchMeteringPointLabels();
    }
  }

  componentDidUpdate() {
    this.updateDiagrams = false;
  }

  handleToggleShowNotes = () => {
    this.updateDiagrams = false;
    this.setState({
      showNotes: !this.state.showNotes,
    });
  };

  handleToggleShowProtocols = () => {
    this.updateDiagrams = false;
    this.setState({
      showProtocols: !this.state.showProtocols,
    });
  };

  handleViewProtocolsInGraph = (id, date, checked, color) => {
    this.updateDiagrams = true;
    if (checked) {
      this.setState({
        protocolTimestampsViewList: this.state.protocolTimestampsViewList.concat({"id":id, "date":date, "color": color})
      })
    } else {
      this.setState({
        protocolTimestampsViewList: this.state.protocolTimestampsViewList.filter((v,_)=> v.id!=id)
      })
    }
  }

  handleToggleShowLabels = () => {
    this.updateDiagrams = false;
    this.setState({
      showLabels: !this.state.showLabels,
    });
  };

  handleErrorOpen = (message) => {
    this.setState({ 
      errorPopupMessage: String(message),
      errorOpen: true,
    });
  };
  handleErrorClose = () => {
    this.setState({ errorOpen: false, errorPopupMessage: "" });
  };

  handleShowCreateProtocolsDialog = () => {
    this.setState({
      showCreateProtocolsDialog : !this.state.showCreateProtocolsDialog,
    });
  };

  setPreviewRefPeriod = (from, to) => {
    const fromDate = new Date(from);
    const toDate = new Date(to);
    if (!isNaN(fromDate) && !isNaN(toDate)) {
      this.setState({
        previewRefPeriod: [fromDate, toDate]
      })
    } else {
      if (this.state.previewRefPeriod !== null) {
        this.setState({
          previewRefPeriod: null
        })
      }
    }
  }

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.fetchMeteringPoint(this.meteringPointId);
    }
  };

  /**
   *
   * @param {*} the search param from input label
   */
  handleInput = (event) => {
    if (event.target.value === "") {
      this.meteringPointId = null;
    } else {
      this.meteringPointId = event.target.value;
    }
  };

  handleAddProtocol = (newProtocol) => {
    var protocolData = [newProtocol];
    
    var thiz = this;
    this.props.context.keycloak
      .updateToken(5)
      .success(function() {
        var config = {
          method: "put",
          url:
            thiz.props.context.config.meteringPointsAPI + "/" + thiz.props.meteringPointId + "/protocol",
          headers: {
            Authorization: "Bearer " + thiz.props.context.keycloak.token
          },
          data: protocolData,
          responseType: "json"
        };

        axios(config)
          .then((response) => {
            thiz.fetchMeteringPointProtocols();
          })
          .catch(error => {
            thiz.handleErrorOpen(error.message);
          });
      })
      .error(function() {
        thiz.props.context.keycloak.login();
      });
  };

  handleCreateLabel = (newLabel) => {
    var labelsData = [newLabel];
    var thiz = this;
    this.props.context.keycloak
      .updateToken(5)
      .success(function() {
        var config = {
          method: "put",
          url:
            thiz.props.context.config.meteringPointsAPI + "/" + thiz.props.meteringPointId + "/label",
          headers: {
            Authorization: "Bearer " + thiz.props.context.keycloak.token
          },
          data: labelsData,
          responseType: "json"
        };
        axios(config)
          .then((response) => {
            // console.log(response)
            thiz.fetchMeteringPointLabels();  
          })
          .catch(error => {
            thiz.handleErrorOpen(error.message);
          });
      })
      .error(function() {
        thiz.props.context.keycloak.login();
      });
  };

  handleDataSelection = (selectedPlotsErg, selectedPlotsVol) => {
    
    var empty = selectedPlotsErg && Object.keys(selectedPlotsErg).length === 0 && selectedPlotsErg.constructor === Object
    var emptyVol = selectedPlotsVol && Object.keys(selectedPlotsVol).length === 0 && selectedPlotsVol.constructor === Object
    var index = []
    if (!empty){
      var indexErg = selectedPlotsErg[Object.keys(selectedPlotsErg)[0]].index
      index = index.concat(indexErg);
    }
    if (!emptyVol) {
      var indexVol = selectedPlotsVol[Object.keys(selectedPlotsVol)[0]].index
      index = index.concat(indexVol);
    }
    this.setState({isDataSelectedOnChart: !empty || !emptyVol})  

    this.setState({selectedDataInfo: {
      "points":index,
      "length":index.length
    }})
  }

  /**
   * Makes an API call to fetch a metering point
   */
  fetchMeteringPoint = () => {
    var meteringPointId = this.meteringPointId;
    this.setState({ meteringPoint: null });

    if (this.meteringPointId) {
      var thiz = this;
      this.props.context.keycloak
        .updateToken(5)
        .success(function () {
          var headers = {
            Authorization: "Bearer " + thiz.props.context.keycloak.token,
          };
          axios
            .get(thiz.props.context.config.meteringPointsAPI + "/" + meteringPointId, {
              headers,
            })
            .then((response) => {
              const meteringPoint = response.data;
              if (!meteringPoint.notes) {
                meteringPoint.notes = [];
              }
              meteringPoint.notes.sort(function (n1, n2) {
                const d1 = new Date(n1.timestamp);
                const d2 = new Date(n2.timestamp);
                return (d1 < d2) - (d1 > d2);
              });
              var protocols = [];
              var labels = [];
              if ("protocols" in response.data) {
                protocols = response.data.protocols;
              }
              if ("labels" in response.data) {
                labels = response.data.labels;
              }
              thiz.props.history.push("/meteringpoint/" + meteringPointId);
              thiz.setState({
                meteringPoint,
                meteringPointLabels: labels,
                meteringPointProtocols: protocols,
                searchedMeteringPoint: meteringPointId,
                errorMessage: null
              });
            })
            .catch((error) => {
              var errMsg;
              if (error && error.response && error.response.status === 404) {
                errMsg = "metering_points.not_found";
              } else {
                errMsg = "metering_points.fetch_error";
              }
              thiz.props.history.push("/meteringpoint");
              thiz.setState({ meteringPoint: null, searchedMeteringPoint: meteringPointId, errorMessage: errMsg });
            });
        })
        .error(function () {
          thiz.props.context.keycloak.login();
        });
    } else {
      this.setState({ meteringPoint: null, searchedMeteringPoint: meteringPointId, errorMessage: null });
    }
  };

  fetchMeteringPointNotes = () => {
    var meteringPointId = this.meteringPointId;
    var thiz = this;

    this.props.context.keycloak
      .updateToken(5)
      .success(function () {
        var headers = {
          Authorization: "Bearer " + thiz.props.context.keycloak.token,
        };
        axios
          .get(thiz.props.context.config.meteringPointsAPI + "/" + meteringPointId, {
            headers,
          })
          .then((response) => {
            let notes = response.data.notes;
            let meteringPoint = thiz.state.meteringPoint;

            if (!notes) {
              meteringPoint.notes = [];
            } else {
              meteringPoint.notes = notes;
            }
            meteringPoint.notes.sort(function (n1, n2) {
              const d1 = new Date(n1.timestamp);
              const d2 = new Date(n2.timestamp);
              return (d1 < d2) - (d1 > d2);
            });
            thiz.setState({ meteringPoint });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .error(function () {
        thiz.props.context.keycloak.login();
      });
  };

  fetchMeteringPointProtocols = () => {
    var meteringPointId = this.meteringPointId;
    var thiz = this;
    
    this.props.context.keycloak
      .updateToken(5)
      .success(function () {
        var headers = {
          Authorization: "Bearer " + thiz.props.context.keycloak.token,
        };
        axios
          .get(thiz.props.context.config.meteringPointsAPI + "/" + meteringPointId, {
            headers,
          })
          .then((response) => {
            let protocols = []
            if ("protocols" in response.data) {
              protocols = response.data.protocols;
            }
            thiz.setState({ meteringPointProtocols: protocols });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .error(function () {
        thiz.props.context.keycloak.login();
      });
  };

  fetchMeteringPointLabels = () => {
    var meteringPointId = this.meteringPointId;
    var thiz = this;
    
    this.props.context.keycloak
      .updateToken(5)
      .success(function () {
        var headers = {
          Authorization: "Bearer " + thiz.props.context.keycloak.token,
        };
        axios
          .get(thiz.props.context.config.meteringPointsAPI + "/" + meteringPointId, {
            headers,
          })
          .then((response) => {
            let labels = []
            if ("labels" in response.data) {
              labels = response.data.labels;
            }
            thiz.setState({ meteringPointLabels: labels });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .error(function () {
        thiz.props.context.keycloak.login();
      });
  };
  
  // Note:  Fix this cannot verify if the key exists
  getProtocolsLength = () => {
    if ("meteringPointProtocols" in this.state && this.state.meteringPointProtocols != null) {
      return this.state.meteringPointProtocols.length;
    } else {
      return 0;
    }
  }

  getLabelsLength = () => {
    if ("meteringPointLabels" in this.state && this.state.meteringPointLabels != null) {
      return this.state.meteringPointLabels.length;
    } else {
      return 0;
    }
  }

  /**
   * this function render the html layout with data value
   */
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <ErrorMessage open={this.state.errorOpen} onClose={this.handleErrorClose} message={this.state.errorPopupMessage} duration={5000} />
        <Grid container>
          <Grid item className={classes.FlexGrow}>
            <Typography variant="h5" color="textSecondary">
              <FormattedMessage id="metering_points.metering_point" />
            </Typography>
          </Grid>
          <Grid item>
            <Input autoFocus={true} placeholder="" onChange={this.handleInput} onKeyPress={this.handleKeyPress} />
            <Tooltip
              title={this.props.intl.formatMessage({
                id: "search",
              })}
            >
              <IconButton onClick={this.fetchMeteringPoint}>
                <SearchIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        {/* NOTE! Adding context as props because using React context somehow causes the injectIntl to fail to find intl */}
        {this.state.meteringPoint ? (
          <div>
            <MeteringPointInformation
              meteringpoint={this.state.meteringPoint}
              handleToggleShowNotes={this.handleToggleShowNotes}
              getProtocolsLength={this.getProtocolsLength}
              getLabelsLength={this.getLabelsLength}
              handleToggleShowProtocols={this.handleToggleShowProtocols}
              handleToggleShowLabels={this.handleToggleShowLabels}
              showCreateProtocolsDialog={this.state.showCreateProtocolsDialog}
              handleShowCreateProtocolsDialog={this.handleShowCreateProtocolsDialog} //JW: not used?
              handleReloadMeteringPoint={this.fetchMeteringPoint}
              context={this.props.context}
              setPreviewRefPeriod={this.setPreviewRefPeriod}
            />
            {this.state.showLabels ? (
              <MeteringPointLabels
                meteringPointId={this.meteringPointId}
                labels={this.state.meteringPointLabels}
                handleToggleShowLabels={this.handleToggleShowLabels}
                context={this.props.context}
                intl={this.props.intl}
                creator={this.props.context.keycloak.tokenParsed.email}
                handleCreateLabel={this.handleCreateLabel}
                selectedDataInfo={this.state.selectedDataInfo}
                isDataSelectedOnChart={this.state.isDataSelectedOnChart}
              />
            ) : null}
            {this.state.showNotes ? (
              <MeteringPointNotes
                meteringPointId={this.state.meteringPoint.metering_point_id}
                notes={this.state.meteringPoint.notes}
                updateMeteringPoint={this.fetchMeteringPointNotes}
                handleToggleShowNotes={this.handleToggleShowNotes}
                context={this.props.context}
              />
            ) : null}
            {this.state.showProtocols?(
              <MeteringPointProtocols
                protocols={this.state.meteringPointProtocols}
                handleToggleShowProtocols={this.handleToggleShowProtocols}
                handleShowCreateProtocolsDialog={this.handleShowCreateProtocolsDialog}
                meteringPointId={this.meteringPointId}
                creator={this.props.context.keycloak.tokenParsed.email}
                context={this.props.context}
                handleAddProtocol={this.handleAddProtocol}
                fetchMeteringPointProtocols={this.fetchMeteringPointProtocols}
                handleViewProtocolsInGraph={this.handleViewProtocolsInGraph}
              />
              ): null
            }
            <Charts
              meteringpoint={this.state.meteringPoint}
              redraw={this.updateDiagrams}
              context={this.props.context}
              alarmsPeriod={this.state.alarmsPeriod}
              protocolTimestamps={this.state.protocolTimestampsViewList}
              handleErrorOpen={this.handleErrorOpen}
              meteringPointLabels={this.state.meteringPointLabels}
              previewRefPeriod={this.state.previewRefPeriod}
              handleDataSelection={this.handleDataSelection}
            />
          </div>
        ) : (this.state.errorMessage ?
          <div style={{color:"red"}}>
            {this.props.intl.formatMessage({id: this.state.errorMessage})}
            <b>{this.state.searchedMeteringPoint}</b>
          </div>
          :
          <div/>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(injectIntl(MeteringPoint)));
