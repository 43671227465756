import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import PropTypes from "prop-types";
import FilterTags from "./FilterTags";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Checkbox,
  FormLabel,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  Radio,
  Tooltip,
  TextField,
  Switch
} from "@material-ui/core";

const Wire = ({ children, ...props }) => children(props);

class AlarmsTableFilter extends React.Component {
  constructor(props) {
    super(props);

    // Deep copy upon initialization
    this.state = {
      selectedProperties: props.selectedProperties,
      selectedDeviation: props.selectedDeviation,
      selectedStatuses: props.selectedStatuses,
      selectedMinimumNumberOfAlarms: props.selectedMinimumNumberOfAlarms,
      selectedMaximumNumberOfAlarms: props.selectedMaximumNumberOfAlarms,
      tags: props.tags
    };
  }

  handleDeviationChange = event => {
    this.setState({ selectedDeviation: event.target.value });
  };

  handleStatusChange = event => {
    var selectedStatuses = this.state.selectedStatuses;
    if (this.state.selectedStatuses.includes(event.target.value)) {
      selectedStatuses = selectedStatuses.filter(function(element) {
        return element !== event.target.value;
      });
    } else {
      selectedStatuses.push(event.target.value);
    }
    this.setState({ selectedStatuses });
  };

  handlePropertyChange = event => {
    var selectedProperties = this.state.selectedProperties;
    if (this.state.selectedProperties.includes(event.target.value)) {
      selectedProperties = selectedProperties.filter(function(element) {
        return element !== event.target.value;
      });
    } else {
      selectedProperties.push(event.target.value);
    }
    this.setState({ selectedProperties });
  };

  handleMinimumNumberOfAlarmsChange = event => {
    this.setState({ selectedMinimumNumberOfAlarms: event.target.value >= 0 ? event.target.value : 0 });
  };

  handleMaximumNumberOfAlarmsChange = event => {
    this.setState({ selectedMaximumNumberOfAlarms: event.target.value >= 0 ? event.target.value : 0 });
  };

  handleUpdate = () => {
    const selectedMinimumNumberOfAlarms = parseInt(this.state.selectedMinimumNumberOfAlarms);
    const selectedMaximumNumberOfAlarms = parseInt(this.state.selectedMaximumNumberOfAlarms);
    this.props.handleUpdate({
      selectedProperties: this.state.selectedProperties,
      selectedDeviation: this.state.selectedDeviation,
      selectedStatuses: this.state.selectedStatuses,
      selectedMinimumNumberOfAlarms: !isNaN(selectedMinimumNumberOfAlarms) && selectedMinimumNumberOfAlarms >= 0 ? selectedMinimumNumberOfAlarms : 0,
      selectedMaximumNumberOfAlarms: !isNaN(selectedMaximumNumberOfAlarms) && selectedMaximumNumberOfAlarms >= 0 ? selectedMaximumNumberOfAlarms : 9999,
    });
    this.props.handleClose();
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle>
          <FormattedMessage id="alarm.filter_table" />
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" alignItems="flex-start" justify="space-between">
            <Grid item >
              <FormLabel component="legend">
                <FormattedMessage id="alarm.meter_reading_property" />
              </FormLabel>
              <FormGroup>
                {this.props.properties.map(property => (
                  <FormControlLabel
                    key={property}
                    control={
                      <Checkbox
                        color="primary"
                        checked={this.state.selectedProperties.includes(
                          property
                        )}
                        onChange={this.handlePropertyChange}
                        value={property}
                      />
                    }
                    label={this.props.intl.formatMessage({
                      id: "alarm.property_" + property
                    })}
                  />
                ))}
              </FormGroup>
            </Grid>
            <Grid item style={{ maxWidth: 100 }}>
              <FormLabel component="legend">
                <FormattedMessage id="alarm.number_of_alarms" />
              </FormLabel>
              <TextField
                id="outlined-number"
                label="Minimum"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                size="small"
                margin="normal"
                onInput={this.handleMinimumNumberOfAlarmsChange}
                value={this.state.selectedMinimumNumberOfAlarms}
              />
              <TextField
                id="outlined-number"
                label="Maximum"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                size="small"
                margin="normal"
                onInput={this.handleMaximumNumberOfAlarmsChange}
                value={this.state.selectedMaximumNumberOfAlarms}
              />
            </Grid>
            <Grid item >
              <FormLabel component="legend">
                <FormattedMessage id="alarm.deviation" />
              </FormLabel>
              <RadioGroup
                onChange={this.handleDeviationChange}
                value={this.state.selectedDeviation}
              >
                <Wire value="deviation_all">
                  {props => (
                    <Tooltip
                      placement="top"
                      title={this.props.intl.formatMessage({
                        id: "alarm.all_description"
                      })}
                    >
                      <FormControlLabel
                        value="deviation_all"
                        control={<Radio color="primary" />}
                        label={this.props.intl.formatMessage({
                          id: "alarm.all"
                        })}
                        {...props}
                      />
                    </Tooltip>
                  )}
                </Wire>
                <Wire value="deviation_larger">
                  {props => (
                    <Tooltip
                      placement="top"
                      title={this.props.intl.formatMessage({
                        id: "alarm.higher_description"
                      })}
                    >
                      <FormControlLabel
                        value="deviation_larger"
                        control={<Radio color="primary" />}
                        label={this.props.intl.formatMessage({
                          id: "alarm.higher"
                        })}
                        {...props}
                      />
                    </Tooltip>
                  )}
                </Wire>
                <Wire value="deviation_smaller">
                  {props => (
                    <Tooltip
                      placement="top"
                      title={this.props.intl.formatMessage({
                        id: "alarm.lower_description"
                      })}
                    >
                      <FormControlLabel
                        value="deviation_smaller"
                        control={<Radio color="primary" />}
                        label={this.props.intl.formatMessage({
                          id: "alarm.lower"
                        })}
                        {...props}
                      />
                    </Tooltip>
                  )}
                </Wire>
              </RadioGroup>
            </Grid>
            <Grid item >
              <FormLabel component="legend">
                <FormattedMessage id="alarm.status" />
              </FormLabel>
              <FormGroup>
                {this.props.statuses.map(status => (
                  <FormControlLabel
                    key={status}
                    control={
                      <Checkbox
                        color="primary"
                        checked={this.state.selectedStatuses.includes(status)}
                        onChange={this.handleStatusChange}
                        value={status}
                      />
                    }
                    label={
                      (status === "" || status === null)
                        ? this.props.intl.formatMessage({ id: "alarm.none" })
                        : this.props.intl.formatMessage({
                            id: "metering_points.status_" + status
                          })
                    }
                  />
                ))}
              </FormGroup>
            </Grid>
            <Grid item >
              <FormLabel component="legend">
                <FormattedMessage id="tags" />
              </FormLabel>
              <Tooltip title={this.props.intl.formatMessage({ id: "alarm.filter_table_toggle_tooltip" })}>
                <FormControlLabel
                  label="And/Or"
                  control={<Switch color="primary" checked={this.props.checked} onChange={this.props.handleChange} />}
                />
              </Tooltip>
              <FormGroup>
                <FilterTags
                  tags={this.state.tags}
                  handleFilterTags={this.props.handleFilterTags}
                  filterTags={this.props.filterTags}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="secondary">
            <FormattedMessage id="cancel" />
          </Button>
          <Button onClick={this.handleUpdate} color="primary" autoFocus>
            <FormattedMessage id="ok" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AlarmsTableFilter.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  properties: PropTypes.array.isRequired,
  selectedProperties: PropTypes.array.isRequired,
  selectedDeviation: PropTypes.string.isRequired,
  selectedMinimumNumberOfAlarms: PropTypes.number.isRequired,
  selectedMaximumNumberOfAlarms: PropTypes.number.isRequired,
  statuses: PropTypes.array.isRequired,
  selectedStatuses: PropTypes.array.isRequired,
  handleUpdate: PropTypes.func.isRequired
};

export default injectIntl(AlarmsTableFilter);
