import React, {memo, useEffect, useState} from "react";
import axios from "axios";
import { FormattedMessage, injectIntl } from "react-intl";
import styles from "../styles";
import { withStyles, makeStyles, MenuItem, TextField, Grid, Dialog, Button,Table,
    TableBody,TableCell,TableHead,TableRow, Typography, IconButton, FormControl, InputLabel, Select, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from '@material-ui/icons/Close';
import MeteringPointTags from "./MeteringPointTags";

const useStyles = makeStyles(styles);

const dialogTitleStyles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  
  const DialogTitle = withStyles(dialogTitleStyles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });




  const MeteringPointAddLabelsDialog = memo((props) => { 
    const classes = useStyles();

    const [availableTags, setAvailabletags] = useState([]);
    const [tags, setTags] = useState([]);
    const [description, setDescription] = useState("");

    const [group, setGroup] = React.useState('');
    const handleChange = (event) => {
      setGroup(event.target.value);
    };

    const handleAddtags = (e) => {
      console.log(e.target.value)
      setTags(tags.concat(e.target.value))
    }

    const handleFetchTags = (tags) => {
      setTags(tags);
      var k = ""
      k.replace()
    }

    //FIXME: This is a copy paste of function in MeteringPointTags.js. Create common lib.
    function dictionaryToArray(dictionary) {
      var array = [];
      for (var key of Object.keys(dictionary)) {
        if (dictionary[key].length > 0) {
          array.push({
            key,
            val: dictionary[key]
          });
        }
      }
      return array;
    }
    
    const createLabel = () => {
      var datetime = new Date(Date.now())
      var label = {
        "tags": dictionaryToArray(tags),
        "description":description,
      //"meteringPointId":props.meteringPointId,
        "creator":props.creator,
        "creation_time":datetime.toISOString(),
        "hidden": false,
        "disabled": false
      }
      
      props.handleCreateLabel(label)
      props.toggleAddLabelsDialog()
    }

    return (
      <>
        {
          props.isDataSelectedOnChart?
          <Dialog fullWidth={true} maxWidth="md" open={props.open} onClose={props.toggleAddLabelsDialog} >
              <DialogTitle id="customized-dialog-title" onClose={props.toggleAddLabelsDialog}>
                  {props.intl.formatMessage({ id: "metering_point.create_label.title" }) }
              </DialogTitle>

              <DialogContent style={{height:300}}>
                <DialogContentText >
                  {props.intl.formatMessage({ id: "metering_point.create_label.info" }).replace("xx",String(props.selectedDataInfo.length))}
                </DialogContentText>

                <Grid container >
                  <Grid item xs={5}  >
                    <MeteringPointTags
                      context={props.context}
                      meteringPointId={props.meteringpointId}
                      compact={false}
                      handleFetchTags={handleFetchTags}
                    />
                  </Grid>
                  
                  <Grid item xs={6} style={{marginLeft:6}}>
                    <TextField
                      id="outlined-textarea"
                      label={props.intl.formatMessage({ id: "metering_point.create_label.description" }) }
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      placeholder={props.intl.formatMessage({ id: "metering_point.create_label.description_placeholder" }) }
                      multiline
                      rows={12}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <MuiDialogActions>
                  <Button variant="contained" onClick={()=>createLabel()} color="primary" disabled={false}>
                      {props.intl.formatMessage({ id: "metering_point.create_label.create" })}
                  </Button>
              </MuiDialogActions>
          </Dialog>
          : 
          <Dialog fullWidth={true} maxWidth="xs" open={props.open} onClose={props.toggleAddLabelsDialog}>
            <DialogTitle id="customized-dialog-title" onClose={props.toggleAddLabelsDialog}>
                {props.intl.formatMessage({ id: "metering_point.create_label.title" }) }
            </DialogTitle>

            <DialogContent>
              <DialogContentText >
                {props.intl.formatMessage({ id: "metering_point.create_label.error_message" }) }
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.toggleAddLabelsDialog} autoFocus>
                close
              </Button>
            </DialogActions>

          </Dialog>

        }
      </>
    );

  });


  export default withStyles(styles)(injectIntl(MeteringPointAddLabelsDialog));