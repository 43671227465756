import React from "react";
//import Plot from "react-plotly.js";
import CloseIcon from "@material-ui/icons/Close";
import { injectIntl } from "react-intl";
import styles from "../styles";
import { withStyles, IconButton, Dialog, DialogActions, DialogContent } from "@material-ui/core";
//Lines below have to be last
import createPlotlyComponent from "react-plotly.js/factory";
import Plotly from "plotly.js-basic-dist-min";
const Plot = createPlotlyComponent(Plotly);

class ChartDTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.getData(),
      modalDiagram: false,
      configPlot: { 
        responsive: true,
        modeBarButtonsToAdd: [
          {
            // name: this.props.intl.formatMessage({id: "chart.zoom"}),
            name: "Magnify",
            icon: {
              "name": this.props.intl.formatMessage({id: "chart.zoom"}),
              "svg": '<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" '
                      +'height="24" viewBox="0 0 24 24" width="24">'
                      +'<g><rect fill="none" height="24" width="24"/></g><g><g><g>'
                      +'<path d="M15,3l2.3,2.3l-2.89,2.87l1.42,1.42L18.7,6.7L21,9V3H15z '
                      +'M3,9l2.3-2.3l2.87,2.89l1.42-1.42L6.7,5.3L9,3H3V9z '
                      +'M9,21 l-2.3-2.3l2.89-2.87l-1.42-1.42L5.3,17.3L3,15v6H9z '
                      +'M21,15l-2.3,2.3l-2.87-2.89l-1.42,1.42l2.89,2.87L15,21h6V15z"/>'
                      +'</g></g></g></svg>'
            },
            click: this.openDialog
          }
        ],
      },
      layout: this.getLayout()
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.convertDataToXY();
    } else if (prevProps.markedLength !== this.props.markedLength
        || (typeof this.props.markedStart === "object"
        && prevProps.markedStart !== this.props.markedStart) ) {
      if (!this.props.waitingForData) {
        this.setState({layout: this.getLayout()});
      }
    }
    if (this.props.chartHoverLabel !== prevProps.chartHoverLabel) {
      this.setState({layout: this.getLayout()})
    }

    if (this.props.protocolTimestamps != prevProps.protocolTimestamps) {
      this.setState({layout: this.getLayout()})
    }
  }

  closeDialog = () => {
    this.setState({ modalDiagram: false });
  };

  openDialog = () => {
    this.setState({ modalDiagram: true });
  };

  /**
   *
   * @param {int} value The number to be rounded
   * @param {int} number_of_decimals How many decimals to round the number to
   */
  roundValue = (value, number_of_decimals) => {
    try {
      return +value.toFixed(number_of_decimals);
    } catch (error) {
      return null;
    }
  };

  getData = () => {
    return [
      {
        x: [1e-9, 1e-8],
        y: [1e-9, 1e-8],
        mode: "markers",
        marker: { color: this.props.colorPattern["bgcolor"] },
        text: ['',''],
      }
    ];
  };

  getLayout = () => {
    const yLabelL = this.props.intl.formatMessage({
      id: "chart.temperature.y"
    });

    let layout = {
      title: {
        text: this.props.intl.formatMessage({ id: this.props.title }),
        y: 1,
        x: 0.5,
        xanchor: "center",
        yanchor: "top",
        pad: {t: 8}
      },
      margin: { l: 50, r: 10, t: 10, b: 10, pad: 10 },
      plot_bgcolor: this.props.colorPattern.bgcolor,
      legend: { orientation: "h" },
      yaxis: { 
        title: yLabelL, 
        titlefont: { size: 9 },
        uirevision: 'time',
        range: [0, this.props.maxTempLimit], 
        zeroline: this.props.zerolines
      },
      yaxis2: {
        uirevision: 'time',
        side: "right",
        overlaying: "y1",
        titlefont: { size: 9 },
        title: this.props.yLabelR,
        showgrid: false,
        zeroline: this.props.zerolines
      },
      hovermode: (this.props.chartHoverLabel === false) ? false : 'x',
      hoverdistance: 1,
      xaxis: {
        uirevision: 'time',
        zeroline: this.props.zerolines,
        tickformatstops: [
          {
            "dtickrange": [null, "M1"],
            "value": "%e %b '%y"
          },
          {
            "dtickrange": ["M1", null],
            "value": "%b %Y"
          }
        ]
      },
      shapes: []
    };

    if (this.props.markedLength > 0) {
      layout.shapes.push(
        {
          type: "rect",
          xref: "x",
          yref: "paper",
          y0: 0,
          x0: this.props.markedStart,
          y1: 1,
          x1: this.props.markedEnd,
          fillcolor: "rgb(100,100,100)",
          opacity: 0.2,
          line: {
            width: 0
          }
        }
      );
    }

    if (this.props.protocolTimestamps) {
      if (this.props.protocolTimestamps.length>0) {
        this.props.protocolTimestamps.map((v,_)=>{
          layout.shapes.push(
            {
              type: 'line',
              xref: 'x',
              yref: 'paper',
              x0: v.date,
              y0: 0,
              x1: v.date,
              y1: 1,
              line: {
                color: v.color,
                width: 4,
                dash: 'dot'
              }
            }
          )
        })        
      }
    }

    return layout;
  };

  convertDataToXY = () => {
    let data = [];
    if (typeof this.props.data === "undefined") return;

    let color_flow = this.props.colorPattern["temp_flow"];
    let color_return = this.props.colorPattern["temp_return"];
    let color_delta = this.props.colorPattern["temp_delta"];
    let color_temperature = this.props.colorPattern["temperature"];
    let group_flow = {
      type: "scatter",
      mode: "lines",
      name: this.props.intl.formatMessage({ id: "chart.flow_temperature" }),
      connectgaps: true,
      line: {
        width: 1,
        color: color_flow
      },
      hovertemplate: this.props.intl.formatMessage({ id: "chart.flow_temperature" })
          +': %{customdata[0]} °C<br>%{customdata[1]|%Y-%m-%d %H:%M}'
    };
    let group_return = {
      type: "scatter",
      mode: "lines",
      name: this.props.intl.formatMessage({ id: "chart.return_temperature" }),
      connectgaps: true,
      line: {
        width: 1,
        color: color_return
      },
      hovertemplate: this.props.intl.formatMessage({ id: "chart.return_temperature" })
          +': %{customdata[0]} °C<br>%{customdata[1]|%Y-%m-%d %H:%M}'
    };
    let group_delta = {
      type: "scatter",
      mode: "lines",
      name: this.props.intl.formatMessage({id: "chart.temperature_difference"}),
      connectgaps: true,
      line: {
        width: 1,
        color: color_delta
      },
      hovertemplate: this.props.intl.formatMessage({id: "chart.temperature_difference"})
          +': %{customdata[0]} °C<br>%{customdata[1]|%Y-%m-%d %H:%M}'
    };
    let group_outdoor = {
      type: "scatter",
      mode: "lines",
      name: this.props.intl.formatMessage({id: "chart.outdoor_temperature"}),
      connectgaps: true,
      line: {
        width: 1,
        color: color_temperature
      },
      hovertemplate: this.props.intl.formatMessage({id: "chart.outdoor_temperature"})
        +': %{customdata[0]} °C<br>%{customdata[1]|%Y-%m-%d}',
      visible: "legendonly"
    };
    let x = [];
    let y_flow = [];
    let y_return = [];
    let y_delta = [];
    let y_outdoor = [];
    let text = [];
    let y_flow_customdata = [];
    let y_return_customdata = [];
    let y_delta_customdata = [];
    let y_outdoor_customdata = [];
    if (typeof this.props.data === "undefined") return;
    this.props.data
      .sort((a, b) => {
        return new Date(a.timestamp) - new Date(b.timestamp);
      })
      .forEach(element => {
        x.push(new Date(element.timestamp));
        text.push(new Date(element.timestamp));
        // text.push(element.timestamp);

        let flow_temp = this.roundValue(element.temperatures.flow_value, 3);
        let return_temp = this.roundValue(element.temperatures.return_value, 3);
        let delta_temp = this.roundValue(element.temperatures.difference_value, 3)
        let outdoor_temp = this.roundValue(element.outdoor_temperature, 3)

        y_flow.push( (flow_temp > this.props.maxTempLimit) ? this.props.maxTempLimit : flow_temp );
        y_return.push( (return_temp > this.props.maxTempLimit) ? this.props.maxTempLimit : return_temp );
        y_delta.push( (delta_temp > this.props.maxTempLimit) ? this.props.maxTempLimit : delta_temp );
        y_outdoor.push( (outdoor_temp > this.props.maxTempLimit) ? this.props.maxTempLimit : outdoor_temp );

        y_flow_customdata.push([flow_temp, element.timestamp]);
        y_return_customdata.push([return_temp, element.timestamp]);
        y_delta_customdata.push([delta_temp, element.timestamp]);
        y_outdoor_customdata.push([outdoor_temp, element.timestamp]);
      }
    );
    group_flow["x"] = x;
    group_flow["y"] = y_flow;
    group_flow["text"] = text;
    group_flow["customdata"] = y_flow_customdata;
    group_return["x"] = x;
    group_return["y"] = y_return;
    group_return["text"] = text;
    group_return["customdata"] = y_return_customdata;
    group_delta["x"] = x;
    group_delta["y"] = y_delta;
    group_delta["text"] = text;
    group_delta["customdata"] = y_delta_customdata;
    group_outdoor["x"] = x;
    group_outdoor["y"] = y_outdoor;
    group_outdoor["text"] = text;
    group_outdoor["customdata"] = y_outdoor_customdata;
    data.push(group_flow);
    data.push(group_return);
    data.push(group_delta);
    data.push(group_outdoor);
    this.setState({ data, layout: this.getLayout() });
  };

  render() {
    const { classes } = this.props;
    const title = this.props.title + "_time";
    return (
      <React.Fragment>
        <Dialog
          open={this.state.modalDiagram}
          onClose={this.closeDialog}
          aria-labelledby="modal-diagram-dialog-title"
          aria-describedby="modal-diagram-description"
          fullWidth
          maxWidth={false}
        >
          <div>
            <DialogActions>
              <IconButton onClick={this.closeDialog}>
                <CloseIcon />
              </IconButton>
            </DialogActions>
            <DialogContent className={classes.p90Height}>
              <Plot
                data={this.state.data}
                layout={this.state.layout}
                config={this.state.configPlot}
                useResizeHandler={true}
                style={{ width: "100%", height: "792px" }}
              />
            </DialogContent>
          </div>
        </Dialog>
        <Plot
          divId={title}
          data={this.state.data}
          layout={this.state.layout}
          config={this.state.configPlot}
          useResizeHandler={true}
          style={{ width: "100%", height: this.props.chartHeight }}
          onHover={p => this.props.pOnHover(title, p, true)}
          onUnhover={p => this.props.pOnHover(title, p, false)}
          onRelayout={r => this.props.pOnRelayout(title, r)}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(injectIntl(ChartDTime));
