import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import unregister from "./registerServiceWorker";
import Keycloak from "keycloak-js";
import App from "./App";
import axios from "axios";

let keycloakConfig = {
  url: null,
  realm: "k2",
  clientId: "k2"
};

let keycloak = null;

axios
  .get("/config.json")
  .then(res => {
    let config = res.data;
    keycloakConfig.url = config.authBaseURL;
    keycloak = Keycloak(keycloakConfig);

    keycloak
      .init({ onLoad: "check-sso", checkLoginIframe: true })
      .success(function(authenticated) {
        if (authenticated) {
          if (keycloak.tokenParsed.resource_access.k2) {
            keycloak.role = keycloak.tokenParsed.resource_access.k2.roles[0];
          }
        }
        ReactDOM.render(
          <App appContext={{ config, keycloak }} />,
          document.getElementById("root")
        );
      })
      .error(function() {
        console.log("failed to initialize");
      });
  })
  .catch(error => {
    console.log(error);
  });

unregister();
