import React, { useState, useEffect } from "react";
//Lines below have to be last
import createPlotlyComponent from "react-plotly.js/factory";
import Plotly from "plotly.js-basic-dist-min";
const Plot = createPlotlyComponent(Plotly);

const ChartForDebet = props => {
  const [data, saveDiagramData] = useState([]);
  const [config] = useState({ responsive: true });

  const getLayout = () => {
    let layout = {
      title: {
        text: props.diaTitle,
        y: 1,
        x: 0.5,
        xanchor: "center",
        yanchor: "top"
      },
      margin: { l: 50, r: 50, t: 20, b: 10, pad: 10 },
      autosize: true,
      plot_bgcolor: props.theme.bgcolor,
      legend: { orientation: "h" },
      yaxis: { title: props.yAxisTitle, titlefont: { size: 9 }, zeroline: true },
      xaxis: { title: props.xAxisTitle, titlefont: { size: 9 }, zeroline: true }
    };
    return layout;
  };
  /**
   *
   * @param {int} value The number to be rounded
   * @param {int} number_of_decimals How many decimals to round the number to
   */
  const roundValue = (value, number_of_decimals) => {
    try {
      return +value.toFixed(number_of_decimals);
    } catch (error) {
      return null;
    }
  };

  /**
   * @description Create diagram data from json /GET transpose ->[property] -> consumption_values
   * @param
   */
  const convertDataToXY = () => {
    let data = [];
    let xTemperatureList = [];
    let yMeasuredList = [];
    let yCalculatedList = [];
    let colorMeasured = props.theme.measured;
    let groupConsumption = {
      type: "scatter",
      mode: "markers",
      name: props.groupTitleConsumed,
      marker: { color: colorMeasured },
      zeroline: false
    };
    let colorCalculated = props.theme.calculated;
    let groupCalculated = {
      type: "scatter",
      mode: "markers",
      name: props.groupTitleCalculated,
      marker: { color: colorCalculated },
      zeroline: false
    };
    if (typeof props.data === "undefined") return;
    props.data.forEach((element, index) => {
      let yMeasured = roundValue(element.measured_consumption, 3);
      let yCalculated = roundValue(element.calculated_consumption, 3);
      let xTemperature = roundValue(element.temperature, 3);
      xTemperatureList.push(xTemperature);
      yMeasuredList.push(yMeasured);
      yCalculatedList.push(yCalculated);
    });
    groupConsumption["x"] = xTemperatureList;
    groupConsumption["y"] = yMeasuredList;
    groupCalculated["x"] = xTemperatureList;
    groupCalculated["y"] = yCalculatedList;
    data.push(groupConsumption);
    data.push(groupCalculated);
    return data;
  };

  useEffect(() => {
    saveDiagramData(convertDataToXY());
  }, []);

  return (
    <React.Fragment>
      <Plot
        data={data}
        layout={getLayout()}
        config={config}
        useResizeHandler={true}
        style={{ width: "100%", height: "400px" }}
      />
    </React.Fragment>
  );
};

export default ChartForDebet;
