import React from "react";
import PropTypes from "prop-types";

import { Snackbar } from "@material-ui/core";

class ErrorMessage extends React.Component {
  render() {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        open={this.props.open}
        onClose={this.props.onClose}
        autoHideDuration={this.props.duration || 3000}
        message={this.props.message}
      />
    );
  }
}

ErrorMessage.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string,
  duration: PropTypes.number
};

export default ErrorMessage;
