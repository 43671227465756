import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

import ConfigurationDatalakeExport from "./ConfigurationDatalakeExport";
import ConfigurationDatalakeExportPreview from "./ConfigurationDatalakeExportPreview";
import ConfigurationCharts from "./ConfigurationCharts";
import ConfigurationLanguage from "./ConfigurationLanguage";

import styles from "../styles";
const useStyles = makeStyles(styles);

export default function Configuration(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h5" color="textSecondary" className={classes.TypographyHeight}>
            <FormattedMessage id="configuration" />
          </Typography>
        </Grid>
      </Grid>
      {props.context.keycloak.role === "admin" && <ConfigurationDatalakeExport />}
      {props.context.keycloak.role === "admin" && <ConfigurationDatalakeExportPreview />}
      <ConfigurationCharts />
      <ConfigurationLanguage 
        updateLanguage={props.updateLanguage}
      />
    </React.Fragment>
  );
}
