
import React from "react";

class ChartBase extends React.Component {
  constructor(props) {
    super(props);
  }

  getDataGroupInfo(data) {
    let nrGroups = 0;
    let nrRefGroups = 0;

    for (let i = 0; i < data?.length; i++) {
      const element = data[i];
      if (element.Group + 1 > nrGroups) {
        nrGroups = element.Group + 1;
      }
      if (element.ref_Group + 1 > nrRefGroups) {
        nrRefGroups = element.ref_Group + 1;
      }
    }

    return {
      nrGroups: nrGroups,
      nrRefGroups: nrRefGroups,
    };
  }

  static addLabelData (intl, label_objects, element) {
    let group_id = element.Group;
    let label_ids = element.labels;
    label_ids.forEach((label_id) => {
      let unique_key = label_id + ":" + group_id
        
        if (!(unique_key in label_objects)) {
          label_objects[unique_key] = {
            "group_id" : group_id,
            "label_id" : label_id,
            "timestamp": [],
            "consumption": [],
            "temperature": [],
            "name": intl.formatMessage({ id: "chart.group" }) + " " + 
                    (group_id+1) + " "+
                    intl.formatMessage({ id: "chart.group.label" }) + " " + label_id,
          }
          let label = label_objects[unique_key]
          label["timestamp"].push(element.timestamp)
          label["consumption"].push(element.consumption)
          label["temperature"].push(element.temperature)
        }
  
      })
    }

    static addExtremeData(intl, invalid_data_objects, element) {
      let group_id = element.Group;
      let unique_key = "invalid-data:"+ group_id;
      if (!(unique_key in invalid_data_objects)) {
        invalid_data_objects[unique_key] = {
          "group_id" : group_id,
          "label_id" : "invalid-data",
          "timestamp": [],
          "consumption": [],
          "temperature": [],
            "name": intl.formatMessage({ id: "chart.group" }) + " " + 
                  (group_id+1) + " "+
                  intl.formatMessage({ id: "chart.group.invalid_data" }),
        }
      }
      let invalid_data = invalid_data_objects[unique_key]
      invalid_data["timestamp"].push(element.timestamp)
      invalid_data["consumption"].push(element.consumption)
      invalid_data["temperature"].push(element.temperature)
    }

  static compareDates = (a, b) => {
    return new Date(a) - new Date(b);
  };

  static populateLabelsExtremeData = (intl, indata) => {
    let label_graph_data = {}
    let extreme_graph_data = {}
    if(indata !== undefined )
    { 
      Array.from(indata)
        .sort((a, b) => this.compareDates(a.timestamp, b.timestamp))
        .forEach((element) => {
          if (element.labels != null && element.labels.length>0) {
            this.addLabelData(intl, label_graph_data, element)
          }
          if (element.extreme_value != null) {
            this.addExtremeData(intl, extreme_graph_data, element)
          }
        });
      return Object.assign({}, label_graph_data, extreme_graph_data);
    }
  };

  updateIndicatorPlotData(data, annotatedPlots, searchTag, useGroupColor, x_axis_data_name) {
    if (typeof x_axis_data_name === 'undefined') {
      console.log("Undeinde param x_axis_data_name")
      return
    }
    for (var key in annotatedPlots) {
      let annotatedData = annotatedPlots[key];
      let newPlotData = this.createIndicatorPlotData(annotatedData, searchTag, useGroupColor, x_axis_data_name);
      if (typeof newPlotData != undefined) {
        data.push(newPlotData);
      }
    }
    return data;
  }

  createIndicatorPlotData(indicatorData, searchTag, useGroupColor, x_axis_data_name) {
    
    if (indicatorData.timestamp.length<1) {
      return;
    }
    let markedPoints = [];
    for (let arrId = 0; arrId < indicatorData.timestamp.length; ++arrId) {
      markedPoints.push({
        x: indicatorData[x_axis_data_name][arrId],
        y: indicatorData["consumption"][arrId],
        text: new Date(indicatorData.timestamp[arrId]),
      });
    }

    let text = [];
    let x = [];
    let y = [];
    markedPoints.sort((p1,p2) => (p1.text < p2.text ? -1 : 1))
    for (const mp of markedPoints) {
      x.push(mp.x);
      y.push(mp.y);
      text.push(mp.text);
    }
    let group_id = parseInt(indicatorData.group_id)
    let color = useGroupColor ? this.props.colorPattern["group" + (group_id+1)] : this.props.colorPattern["marked"];
    //let color = this.props.colorPattern["marked"];
    let newPlotData = {
      type: "scatter",
      mode: "markers",
      name: indicatorData.name /*this.props.intl.formatMessage({ id: "chart.marked" })*/,
      marker: {
        color: color, symbol: this.props.chartSymbol,
        size: this.props.chartSymbolSize + 5 ,
        line: {color: this.props.colorPattern["black"], width: 1},
        opacity: 0.5
      },
      hovertemplate: "hover message",
      x: x,
      y: y,
      text: text,
      "searchTag": searchTag,
    };
    return newPlotData;
  }
}

export default ChartBase;
