import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { injectIntl } from "react-intl";
import styles from "../styles";
import { withStyles, IconButton, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import ChartBase from "./ChartBase.js";
//Lines below have to be last
import createPlotlyComponent from "react-plotly.js/factory";
import Plotly from "plotly.js-basic-dist-min";
const Plot = createPlotlyComponent(Plotly);

/**
 * Energy-Flow time diagram
 */
class ChartEFTime extends ChartBase {
  constructor(props) {
    super(props);
    this.state = {
      data: this.getData(),
      modalDiagram: false,
      configPlot: { 
        responsive: true,
        modeBarButtonsToAdd: [
          {
            // name: this.props.intl.formatMessage({id: "chart.zoom"}),
            name: "Magnify",
            icon: {
              "name": this.props.intl.formatMessage({id: "chart.zoom"}),
              "svg": '<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" '
                      +'height="24" viewBox="0 0 24 24" width="24">'
                      +'<g><rect fill="none" height="24" width="24"/></g><g><g><g>'
                      +'<path d="M15,3l2.3,2.3l-2.89,2.87l1.42,1.42L18.7,6.7L21,9V3H15z '
                      +'M3,9l2.3-2.3l2.87,2.89l1.42-1.42L6.7,5.3L9,3H3V9z '
                      +'M9,21 l-2.3-2.3l2.89-2.87l-1.42-1.42L5.3,17.3L3,15v6H9z '
                      +'M21,15l-2.3,2.3l-2.87-2.89l-1.42,1.42l2.89,2.87L15,21h6V15z"/>'
                      +'</g></g></g></svg>'
            },
            click: this.openDialog
          }
        ],
      },
      layout: this.getLayout()
    };
  }

  LABELQUANTITIES = {
    "energy": {false: "chart.label.energy", true: 'chart.label.power'},
    "flow": {false: "chart.label.volume", true: "chart.label.flow"}
  }
  UNITS = {
    "energy": {false: "MWh", true: 'kW'},
    "flow": {false: "m³", true: "m³/h"}
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedPlots !== this.props.selectedPlots) {
      this.updateSelection(this.state.data, this.props.selectedPlots);      
    }
    if (prevProps.labelPlots !== this.props.labelPlots) {
      this.updateLabels(this.state.data, this.props.labelPlots);      
    }

    if (prevProps.data !== this.props.data 
        || prevProps.useAltUnit !== this.props.useAltUnit) {
      this.convertDataToXY();
    } else if (prevProps.markedLength !== this.props.markedLength
        || (typeof this.props.markedStart === "object"
        && prevProps.markedStart !== this.props.markedStart) ) {
      if (!this.props.waitingForData) {
        this.setState({layout: this.getLayout()});
      }
    }
    if (this.props.chartHoverLabel !== prevProps.chartHoverLabel
      || prevProps.useAltUnit !== this.props.useAltUnit) {
      this.setState({layout: this.getLayout()})
    }

    if (this.props.protocolTimestamps != prevProps.protocolTimestamps) {
      this.setState({layout: this.getLayout()})
    }

  }

  closeDialog = () => {
    this.setState({ modalDiagram: false });
  };

  openDialog = () => {
    this.setState({ modalDiagram: true });
  };
  /**
   *
   * @param {int} value The number to be rounded
   * @param {int} numberOfDecimals How many decimals to round the number to
   */
  roundValue = (value, numberOfDecimals) => {
    try {
      return +value.toFixed(numberOfDecimals);
    } catch (error) {
      return null;
    }
  };

  compareDates = (a, b) => {
    return new Date(a) - new Date(b);
  };

  updateSelection = (data, selectedPlots) => {
    data = this.removePlotdata(data, "selection");
    data = this.updateIndicatorPlotData(data, selectedPlots, "selection", false, "timestamp");
    this.setState({ data, revision: this.state.revision+1 });
  }

  updateLabels = (data, labelPlots) => {
    data = this.updateIndicatorPlotData(data, labelPlots, "label", true, "timestamp");
    this.setState({ data, revision: this.state.revision+1 });  
  }

  removePlotdata = (data, searchTag) => {
    let newdata = data.filter((item) => {return item.searchTag !== searchTag});
    return newdata;
  }

  convertAlarmsToXy = data => {
    const LABELQUANTITY = this.LABELQUANTITIES[this.props.title][this.props.useAltUnit];
    const UNIT = this.UNITS[this.props.title][this.props.useAltUnit];
    if (typeof data === "undefined" || data.lenth === 0) return;
    let groupAlarms = {
      type: "scatter",
      mode: "markers",
      name: this.props.intl.formatMessage({ id: "chart.alarms" }),
      connectgaps: false,
      marker: { 
        color: this.props.colorPattern.alarms,
        symbol: this.props.chartSymbol,
        size: this.props.chartSymbolSize
      },
      hovertemplate: this.props.intl.formatMessage({ id: LABELQUANTITY })
          +': %{y} '+UNIT,
    };
    let x = [];
    let y = [];
    data.forEach(meterValue => {
      if (meterValue.calculated.deviation_level === "critical") {
        x.push(new Date(meterValue.consumption.from));
        y.push(this.roundValue(meterValue.consumption.value, 3));
      }
    });
    groupAlarms["x"] = x;
    groupAlarms["y"] = y;
    groupAlarms["text"] = x;
    return groupAlarms;
  };

  convertTemperatureToXY = (indata) => {
    let group = {
      type: "scatter",
      mode: "lines",
      name: this.props.intl.formatMessage({ id: "chart.outdoor_temperature" }),
      connectgaps: false,
      anchor: "x",
      yaxis: "y2",
      zeroline: false,
      line: { width: 1, color: this.props.colorPattern.temperature },
      showgrid: false,
      showline: false,
      hovertemplate: this.props.intl.formatMessage({id: "chart.outdoor_temperature"})
          +': %{y} °C'
    };
    let x = [];
    let y = [];

    //Read from all regression arrays
    var allRegressions = [];
    if(this.props.data !== undefined){
      for (const regArray of Array.from(this.props.data)) {
        allRegressions.push(regArray);
      }
    }
    allRegressions
      .sort((a, b) => this.compareDates(a.timestamp, b.timestamp))
      .forEach((element) => {
        x.push(new Date(element.timestamp));
        y.push(this.roundValue(element.temperature, 3));
      });
    group["x"] = x;
    group["y"] = y;
    group["text"] = x;
    return group;
  };


  
  createGroup = (index, name) => {
    const LABELQUANTITY = this.LABELQUANTITIES[this.props.title][this.props.useAltUnit];
    const UNIT = this.UNITS[this.props.title][this.props.useAltUnit];
    let color = "group" + (index + 1);
    let group = {
      type: "scatter",
      mode: "lines",
      name: name + " " + (index + 1),
      connectgaps: false,
      yaxis: "y1",
      line: { width: 1, color: this.props.colorPattern[color] },
      hovertemplate: this.props.intl.formatMessage({ id: LABELQUANTITY })
          +': %{y} '+UNIT,
    };
    group["x"] = [];
    group["y"] = [];
    group["text"] = [];
    group["timestamp"] = []
    group["group_id"] = index;

    return group;
  };

  populateGroups = (indata, groups) => {
    if(indata !== undefined )
    { 
      Array.from(indata)
        .sort((a, b) => this.compareDates(a.timestamp, b.timestamp))
        .forEach((element) => {
          let groupNr = parseInt(element.Group);
          let group = groups[groupNr];
          group['x'].push(new Date(element.timestamp));
          group['y'].push(this.roundValue(element.consumption, 3));
          group['timestamp'].push(element.timestamp);
        });

      return;
    }
  };

  populateReg = (indata, groups) => {
    if(indata !== undefined )
    { 
      Array.from(indata)
        .sort((a, b) => this.compareDates(a.timestamp, b.timestamp))
        .forEach((element) => {
          let groupNr = parseInt(element.Group);
          let group = groups[groupNr];
          group['x'].push(new Date(element.timestamp));
          group['y'].push(this.roundValue(element.model, 3));
        });
    }
  }

  populateRef = (indata, groups) => {
    if(indata !== undefined )
    {
      Array.from(indata)
      .sort((a, b) => this.compareDates(a.timestamp, b.timestamp))
      .forEach((element) => {
        let groupNr = parseInt(element.ref_Group);
        let group = groups[groupNr];
        if (group != undefined) {
          group['x'].push(new Date(element.timestamp));
          group['y'].push(this.roundValue(element.model_ref, 3));
        }
      });
    }
  }

 

  createRef = (index) => {
    const LABELQUANTITY = this.LABELQUANTITIES[this.props.title][this.props.useAltUnit];
    const UNIT = this.UNITS[this.props.title][this.props.useAltUnit];
    let color = "ref" + (index + 1);
    let group = {
      type: "scatter",
      mode: "lines",
      name: "Ref " + (index + 1),
      connectgaps: false,
      line: { width: 1, color: this.props.colorPattern[color] },
      hovertemplate: this.props.intl.formatMessage({ id: LABELQUANTITY })
          +': %{y} '+UNIT,
    };
    group["x"] = [];
    group["y"] = [];
    group["text"] = [];

    return group;
  }

  createReg = (index) => {
    const LABELQUANTITY = this.LABELQUANTITIES[this.props.title][this.props.useAltUnit];
    const UNIT = this.UNITS[this.props.title][this.props.useAltUnit];
    let color = "reg" + (index + 1);
    let group = {
      type: "scatter",
      mode: "lines",
      name: "Reg " + (index + 1),
      connectgaps: false,
      line: {
        width: 1,
        color: this.props.colorPattern[color]
      },
      hovertemplate: this.props.intl.formatMessage({ id: LABELQUANTITY })
          +': %{y} '+UNIT,
    };
    group["x"] = [];
    group["y"] = [];
    group["text"] = [];

    return group;
  }

  convertTemperatureToXY_new = () => {
    let group = {
      type: "scatter",
      mode: "lines",
      name: this.props.intl.formatMessage({ id: "chart.outdoor_temperature" }),
      connectgaps: false,
      anchor: "x",
      yaxis: "y2",
      zeroline: false,
      line: { width: 1, color: this.props.colorPattern.temperature },
      showgrid: false,
      showline: false,
      hovertemplate: this.props.intl.formatMessage({id: "chart.outdoor_temperature"})
          +': %{y} °C'
    };
    let x = [];
    let y = [];
    this.props.data
//      .filter(a => a.Group==1)
      .sort((a, b) => this.compareDates(a.timestamp, b.timestamp))
      .forEach((element) => {
        x.push(new Date(element.timestamp));
        y.push(this.roundValue(element.temperature, 3));
      });

    group["x"] = x;
    group["y"] = y;
    group["text"] = x;
    return group;

  };

  convertDataToXY = () => {
    let info = this.getDataGroupInfo(this.props.data);

    let temp = this.convertTemperatureToXY(this.props.data);

    let refs = []
    for (let index = 0; index < info.nrRefGroups; ++index) {
      refs.push(this.createRef(index));
    }
    this.populateRef(this.props.data, refs)

    let regs = []
    for (let index = 0; index < info.nrGroups; ++index) {
      regs.push(this.createReg(index));
    }
    this.populateReg(this.props.data, regs)

    let groups = []
    for (let index = 0; index < info.nrGroups; ++index) {
      groups.push(this.createGroup(index, this.props.intl.formatMessage({ id: "chart.group" })));
    }

    this.populateGroups(this.props.data, groups)

    let alarms = this.convertAlarmsToXy(this.props.alarms);

    let data = []
    data.push(temp);
    data.push(...refs);
    data.push(...regs);
    data.push(...groups);
    data.push(alarms);
    this.setState({ data, layout: this.getLayout() });
  }

  convertDataToXY_old = () => {
    let data = [];
    if (typeof this.props.data.reg === "undefined" ||
        this.props.data.reg === undefined ||
        this.props.data.reg === null ||
        this.props.data.reg.length === 0) {
      return;
    }
    if (typeof this.props.data.ref === "undefined" ||
        this.props.data.ref === undefined ||
        this.props.data.ref === null ||
        this.props.data.ref.length === 0) {
      return;
    }

    // Temperatur
    let group = this.convertTemperatureToXY();
    data.push(group);

    //Reference
    this.props.data.ref.forEach((element, index) => {
      let group = this.convertRefToXy(element, index);
      data.push(group);
    });

    //Regression
    this.props.data.reg.forEach((element, index) => {
      let group = this.convertRegToXY(element, index);
      data.push(group);
    });

    //Groups
    this.props.data.reg.forEach((element, index) => {
      let group = this.convertGroupToXY(element, index,
          this.props.intl.formatMessage({ id: "chart.group" }));
      data.push(group);
    });

    //Alarms
    let groupAlarms = this.convertAlarmsToXy(this.props.alarms);
    data.push(groupAlarms);

    //Change units if needed
    var scf = 1;
    if (this.props.useAltUnit) {
      if (this.props.title === "energy") {
        scf = 1000.0/24.0;
      } else if (this.props.title === "flow") {
        scf = 1/24.0;
      }
    }
    if (scf !== 1) {
      for (let i = 1; i < data.length; ++i) {
        if (data[i]["y"]) {
          for (let j = 0; j < data[i]["y"].length; ++j) {
            data[i]["y"][j] *= scf;
          }
        }
      }
    }
    this.setState({ data, layout: this.getLayout() });
  };

  getData = () => {
    return [
      {
        x: [1e-9, 1e-8],
        y: [1e-9, 1e-8],
        mode: "markers",
        marker: { color: this.props.colorPattern["bgcolor"] },
        text: ['',''],
      }
    ];
  };

  getLayout = () => {
    var yLabelL;
    if (this.props.title === "energy") {
      yLabelL = (this.props.useAltUnit) ? "chart.energy_daily_average" : "chart.energy_per_day";
    } else if (this.props.title === "flow") {
      yLabelL = (this.props.useAltUnit) ? "chart.flow_daily_average" : "chart.flow_per_day";
    }
    yLabelL = this.props.intl.formatMessage({ id: yLabelL });
    const yLabelR = this.props.intl.formatMessage({
      id: "chart.temperature_outdoor_daily_average"
    });

    let layout = {
      title: {
        text: this.props.intl.formatMessage({ id: this.props.title }),
        y: 1,
        x: 0.5,
        xanchor: "center",
        yanchor: "top",
        pad: {t: 8}
      },
      margin: { l: 50, r: 50, t: 10, b: 10, pad: 10 },
      plot_bgcolor: this.props.colorPattern.bgcolor,
      paper_bgcolor: this.props.colorPattern.papercolor,
      legend: { orientation: "h" },
      yaxis: { uirevision: 'time',title: yLabelL, titlefont: { size: 9 }, zeroline: this.props.zerolines },
      yaxis2: {
        uirevision: 'time',
        side: "right",
        overlaying: "y1",
        titlefont: { size: 9 },
        title: yLabelR,
        showgrid: false,
        zeroline: this.props.zerolines
      },
      hovermode: (this.props.chartHoverLabel === false) ? false : 'x',
      hoverdistance: 1,
      xaxis: {
        uirevision: 'time',
        zeroline: this.props.zerolines,
        tickformatstops: [
          {
            "dtickrange": [null, "M1"],
            "value": "%e %b '%y"
          },
          {
            "dtickrange": ["M1", null],
            "value": "%b %Y"
          }
        ]
      },
      shapes: []
    };

    if (this.props.markedLength > 0) {
      layout.shapes.push(
        {
          type: "rect",
          xref: "x",
          yref: "paper",
          y0: 0,
          x0: this.props.markedStart,
          y1: 1,
          x1: this.props.markedEnd,
          fillcolor: "rgb(100,100,100)",
          opacity: 0.2,
          line: {
            width: 0
          }
        }
      );
    }

    if (this.props.protocolTimestamps) {
      if (this.props.protocolTimestamps.length>0) {
        this.props.protocolTimestamps.map((v,_)=>{
          layout.shapes.push(
            {
              type: 'line',
              xref: 'x',
              yref: 'paper',
              x0: v.date,
              y0: 0,
              x1: v.date,
              y1: 1,
              line: {
                color: v.color,
                width: 4,
                dash: 'dot'
              }
            }
          )
        })        
      }
    }
    return layout;
  };

  render() {
    const { classes } = this.props;
    const title = this.props.title + "_time";
    return (
      <React.Fragment>
        <Dialog
          open={this.state.modalDiagram}
          onClose={this.closeDialog}
          aria-labelledby="modal-diagram-dialog-title"
          aria-describedby="modal-diagram-description"
          fullWidth
          maxWidth={false}
        >
          <div>
            <DialogActions>
              <IconButton onClick={this.closeDialog}>
                <CloseIcon />
              </IconButton>
            </DialogActions>
            <DialogContent className={classes.p90Height}>
              <Plot
                divId={title}
                data={this.state.data}
                layout={this.state.layout}
                config={this.state.configPlot}
                useResizeHandler={true}
                style={{ width: "100%", height: "792px" }}
              />
            </DialogContent>
          </div>
        </Dialog>
        <Plot
          divId={title}
          data={this.state.data}
          layout={this.state.layout}
          config={this.state.configPlot}
          useResizeHandler={true}
          style={{ width: "100%", height: this.props.chartHeight }}
          onHover={p => this.props.pOnHover(title, p, true)}
          onUnhover={p => this.props.pOnHover(title, p, false)}
          onRelayout={r => this.props.pOnRelayout(title, r)}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(injectIntl(ChartEFTime));
