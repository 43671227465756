import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import axios from "axios";
import Cookies from "js-cookie";
import localforage from 'localforage';

import { withStyles, Typography, Grid, MenuItem, TextField, Tooltip, IconButton } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import SyncIcon from "@material-ui/icons/Sync";

import AppContext from "../AppContext";
import ErrorMessage from "./ErrorMessage";
import AlarmsTable from "./AlarmsTable";

import styles from "../styles";
import { statuses } from "./MeteringPointInformation";

const periods = {
  1: {
    count: 1,
    period: "day"
  },
  7: {
    count: 1,
    period: "week"
  },
  14: {
    count: 2,
    period: "weeks"
  },
  30: {
    count: 1,
    period: "month"
  },
  90: {
    count: 3,
    period: "months"
  },
  180: {
    count: 6,
    period: "months"
  },
  365: {
    count: 1,
    period: "year"
  }
};

class Alarms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      periodAnchorEl: null,
      errorOpen: false,
      loadingAlarms: true,
      alarms: [],
      alarmsUpdated: null,
      reconstructingAlarmLists: false,
      period: Cookies.get("k2_alarms_period") || "30",
      tags: []
    };
  }

  componentDidMount() {
    var alarmsData = [
      localforage.getItem('alarms'),
      localforage.getItem('alarmsUpdated'),
      localforage.getItem('alarmsPeriod'),
      localforage.getItem('tagsList')
    ]
    Promise.all(alarmsData).then((values) => {
      if (Boolean(values[0]) && Boolean(values[1]) && 
          Boolean(values[2]) && Boolean(values[3])) {
        this.setState({
          alarms: values[0],
          alarmsUpdated: new Date(values[1]),
          period: values[2],
          tags: values[3],
          loadingAlarms: false
        })
      } else {
        this.fetchAlarms();
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.period !== prevState.period) {
      this.fetchAlarms();
    }
  }

  rebuildAlarmLists() {
    var thiz = this;
    const data = {};
    thiz.setState({ 
      reconstructingAlarmLists: true,
      errorOpen: true,
      errorMessage: thiz.props.intl.formatMessage({
        id: "alarm.rebuilding"
      })
    });

    var headers = { Authorization: "Bearer " + thiz.context.keycloak.token };
    thiz.context.keycloak
      .updateToken(5)
      .success(function() {
        axios
          .put(thiz.context.config.meterReadingsAPI + "/alarms", data, {headers})
          .then((response) => {
            thiz.setState({ 
              reconstructingAlarmLists: false,
              errorOpen: true,
              errorMessage: thiz.props.intl.formatMessage({
                id: "alarm.rebuild_done"
              })
            });
          })
          .catch(error => {
            console.log(error);
            let errMsg = (error.response && error.response.status) ?
                          error.response.status: error;
            thiz.setState({
              errorOpen: true,
              errorMessage: errMsg,
              loadingAlarms: false,
              reconstructingAlarmLists: false
            });
          });
      })
      .error(function() {
        thiz.context.keycloak.login();
      });
  };


  fetchAlarms = () => {
    var thiz = this;

    function fetchAlarms(token, period) {
      var endDate = new Date();
      var startDate = new Date();
      startDate.setUTCDate(endDate.getUTCDate() - period);
      endDate.setUTCDate(endDate.getUTCDate() - 1);

      var headers = { Authorization: "Bearer " + token };
      var params = {
        include_from: startDate.toISOString().substring(0, 10),
        include_to: endDate.toISOString().substring(0, 10),
        interval: "daily"
      };

      return axios.get(thiz.context.config.meterReadingsAPI + "/alarms", {
        headers,
        params
      });
    }

    function fetchLastRun(token) {
      var headers = {Authorization: "Bearer " + token};
      return axios.get(thiz.context.config.meterReadingsAPI + "/alarms/last_run", { headers })
    }

    // function fetchMeteringPoints(token) {
    //   var headers = { Authorization: "Bearer " + token };
    //   var params = {
    //     fields: "status,tags,location.address.street_address",
    //     limit: 999999999
    //   };

    //   return axios.get(thiz.context.config.meteringPointsAPI, {
    //     headers,
    //     params
    //   });
    // }

    // function fetchTags(token) {
    //   var headers = { Authorization: "Bearer " + token };
    //   return axios.get(thiz.context.config.meteringPointsAPI + "/tags", {
    //     headers
    //   });
    // }

    this.setState({ loadingAlarms: true, alarms: [] });
    this.context.keycloak
      .updateToken(5)
      .success(function() {
        axios
          .all([
            fetchAlarms(thiz.context.keycloak.token, thiz.state.period),
            fetchLastRun(thiz.context.keycloak.token)
          ])
          .then(
            axios.spread(function(alarmsResponse, lastRunResponse) {
              var tags = {};
              for (var alarm of alarmsResponse.data) {
                alarm.metering_point_status = (Boolean(alarm.status) &&
                    statuses.includes(alarm.status[0])) ? alarm.status[0] : "";
                alarm.tags = alarm.tags || [];
                alarm.street_address = alarm.street_address || "";
                // Gather tags
                for (let tag of alarm.tags) {
                  if (!(tag.key in tags)) {
                    tags[tag.key] = new Set(tag.val);
                  } else {
                    tag.val.forEach(tags[tag.key].add, tags[tag.key]);
                  }
                }
              }
              var tagsList = [];
              for (let [key,val] of Object.entries(tags)) {
                tagsList.push({"key": key, "val": Array.from(val)});
              }
              var alarmsUpdated = lastRunResponse.data?.timestamp;
              if (alarmsUpdated != null && alarmsUpdated.charAt(alarmsUpdated.length-1) !== "Z") {
                alarmsUpdated = alarmsUpdated.concat("Z");
              }
              thiz.setState({
                alarms: alarmsResponse.data,
                period: thiz.state.period,
                alarmsUpdated: new Date(alarmsUpdated),
                loadingAlarms: false,
                tags: tagsList
              });

              localforage.setItem('alarms', alarmsResponse.data);
              localforage.setItem('alarmsUpdated', lastRunResponse.data?.timestamp);
              localforage.setItem('alarmsPeriod', thiz.state.period);
              localforage.setItem('tagsList', tagsList);
            })
          )
          .catch(error => {
            console.log(error);
            let errMsg = (error.response && error.response.status) ?
                          error.response.status: error;
            thiz.setState({
              errorOpen: true,
              errorMessage: thiz.props.intl.formatMessage({
                id: "alarm.error_message_fetching_data"
              }) + " ("  + errMsg + ")",
              loadingAlarms: false
            });
          });
      })
      .error(function() {
        thiz.context.keycloak.login();
      });
  };

  handlePeriodChoose = event => {
    Cookies.set("k2_alarms_period", event.target.value, {expires: new Date(Date.now()+1000000000000), sameSite: "lax"});
    this.setState({ period: event.target.value });
  };

  handleErrorClose = () => {
    this.setState({ errorOpen: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <ErrorMessage open={this.state.errorOpen} onClose={this.handleErrorClose} message={this.state.errorMessage} />
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h5" color="textSecondary">
              <FormattedMessage id="alarms" />
            </Typography>
          </Grid>
          <Grid item xs={5} className={classes.AlignRight}>
            <div style={{position:"relative", height: "100%"}}>
              <Typography variant="body2" style={{position:"absolute", bottom:4, right:0}}>
                <FormattedMessage id="alarm.last_updated" />
                {(this.state.alarmsUpdated) ? this.state.alarmsUpdated.toLocaleString("sv-SE", {timeZoneName: "short"}) : null}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={1} className={classes.AlignRight}>
            <Tooltip
              placement="top"
              title={this.props.intl.formatMessage({id: "alarm.update"})}
            >
              <IconButton
                onClick={() => {this.fetchAlarms()}}
                style={{marginLeft: "-16px"}}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              placement="top"
              title={this.props.intl.formatMessage({id: "alarm.rebuild"})}
            >
              <IconButton
                onClick={() => {this.rebuildAlarmLists()}}
                style={{marginLeft: "-16px"}}
                disabled={this.state.reconstructingAlarmLists}
              >
                <SyncIcon />
              </IconButton>
            </Tooltip>
            <TextField
              id="standard-select-currency"
              select
              label="Period"
              value={this.state.period}
              onChange={this.handlePeriodChoose}
            >
              {Object.keys(periods).map(period => (
                <MenuItem key={period} value={period}>
                  {periods[period]["count"]}
                  &nbsp;
                  <FormattedMessage id={periods[period]["period"]} />
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <AlarmsTable
          alarms={this.state.alarms}
          loading={this.state.loadingAlarms}
          tags={this.state.tags}
          period={this.state.period}
        />
      </React.Fragment>
    );
  }
}

Alarms.contextType = AppContext;

export default withStyles(styles)(injectIntl(Alarms));
