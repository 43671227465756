import React, {memo, useEffect, useState} from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";

import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  makeStyles,
  Typography,
  TableContainer,
  Checkbox,
  Collapse,
  Fab
} from "@material-ui/core";

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DeleteIcon from '@material-ui/icons/Delete';

import styles from "../styles";
import {green,purple,orange,red,blue, teal, lime, brown, deepOrange, blueGrey, grey, common} from '@material-ui/core/colors'


const makeColors = (nColors) => {
    var colorPickers = [ green,purple,orange,red,brown, blue,  deepOrange, blueGrey, teal, lime];
    var colors = [];
    var j = 0; var shade = 700
    for (let i=0; i<nColors; i++) {
        if (j>=colorPickers.length) { 
          j = 0; 
          shade += shade*2;
        }
        colors.push(colorPickers[j][shade])
        j += 1
    }
    return colors;
}


const StyledTableCell = withStyles((theme) => ({
  root: {
    borderBottom: "none"
  },
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[700],
  },
  body: {
    fontSize: 15,
  },
}))(TableCell);

const StyledSubTableCell = withStyles((theme) => ({
  root: {
    borderBottom: "none"
  },
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[800],
  },
  body: {
    fontSize: 13,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
  },
}))(TableRow);


const useStyles = makeStyles(styles);

const DeviationTableRow = memo((props) => {

  const [isSelected, setIsSelected] = useState(false);

  const toggleSelection = () => {
    setIsSelected(!isSelected);
    if (props.deleteDeviation) {
      props.deleteDeviation(props.deviation.id);
    }
    props.handleSelect(props.deviation.id);
  }

  return (
      <TableRow key={props.index} hover selected={props.checkIfSelected(props.deviation.id)} onClick={toggleSelection}>
          <StyledSubTableCell >
            {props.index}
          </StyledSubTableCell>
          <StyledSubTableCell >
          {
              props.deviation.cause?
              <FormattedMessage id={"metering_point.create_protocol_deviation_cause_" + props.deviation.cause} />
              :null
          }
          </StyledSubTableCell>
          <StyledSubTableCell>
          {
              props.deviation.component?
              props.deviation.component_other.length>0?props.deviation.component_other:props.intl.formatMessage({id:"metering_point.create_protocol_" + props.deviation.component})
              :null
          }
          </StyledSubTableCell>
          <StyledSubTableCell >
          {props.deviation.fault_description}
          </StyledSubTableCell>
          <StyledSubTableCell >
          {
              props.deviation.energy_usage_change?
              <FormattedMessage id={"metering_point.create_protocol_energy_usage_change_" + props.deviation.energy_usage_change} />
              :null
          }
          </StyledSubTableCell>
          <StyledSubTableCell >
          {
              props.deviation.action?
              props.deviation.action_other.length>0?props.deviation.action_other:props.intl.formatMessage({id:"metering_point.create_protocol_action_" + props.deviation.action})
              :null
          }
          </StyledSubTableCell>
          <StyledSubTableCell >
          {
              props.deviation.status?
              <FormattedMessage id={"metering_point.create_protocol_" + props.deviation.status} />
              :null
          }
          </StyledSubTableCell>
      </TableRow>
  );
});


const DeviationTable = memo((props)=> {

    const deviations = props.deviations;

    const [selected, setSelected] = useState([]);

    const handleSelect = (id) => {
      if (selected.includes(id)) {
        setSelected(selected.filter((v,_)=>v!=id))
      } else {
        setSelected(selected.concat(id));
      }
    }

    const checkIfSelected = (id) => {
      return selected.includes(id);
    }

    const subColumns = [
        {id:"Deviation Id",label:props.intl.formatMessage({id: "metering_point.protocol_panel.sub_cols.deviation_id"})},
        {id: "Cause", label:props.intl.formatMessage({id: "metering_point.protocol_panel.sub_cols.cause"})},
        {id:"Component",label:props.intl.formatMessage({id: "metering_point.protocol_panel.sub_cols.component"})},
        {id:"Fault Description",label:props.intl.formatMessage({id: "metering_point.protocol_panel.sub_cols.fault_description"})},
        {id:"Energy Usage Change",label:props.intl.formatMessage({id: "metering_point.protocol_panel.sub_cols.energy_usage_change"})},
        {id:"Action",label:props.intl.formatMessage({id: "metering_point.protocol_panel.sub_cols.action"})},
        {id:"Status",label:props.intl.formatMessage({id: "metering_point.protocol_panel.sub_cols.status"})},
    ];

    return (
        <Table size="small" style={{marginBottom:16, marginLeft:"1%", }} >
            <TableHead>
            <TableRow>
                {
                  subColumns.map(col =>(
                    <StyledSubTableCell> 
                      <Typography style={{fontSize:13, fontWeight:"bold", minWidth:"15%"}}> 
                          {col.label} 
                      </Typography>
                    </StyledSubTableCell>
                  ))
                }
            </TableRow>
            </TableHead>
            <TableBody>
            {
                Object.keys(deviations).map((k)=> (
                  <DeviationTableRow
                    {...props}
                    index={k}
                    checkIfSelected={checkIfSelected}
                    handleSelect={handleSelect}
                    deviation={deviations[k]}
                  />
                ))
            }
            </TableBody>
        </Table>
    );

});

const Row = memo((props) => {
    const row = props.row;
    const deviations = row.deviations;
    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState(false);
    const [checkBoxColor, setCheckboxColor] = useState("");
    const getSubCell = () => {
        //TODO: add this subroutine  for getting styled subcell
    }

    var CustomCheckbox = withStyles({
      root: {
        color: checked?checkBoxColor:"default" ,
        '&$checked': {
          color: checked?checkBoxColor:"default",
        },
      },
      checked: {},
    })((props) => <Checkbox color="default" {...props} />);
    

    return (
      <React.Fragment>
        <StyledTableRow >
          
          <StyledTableCell >
            {row.protocol_id}
          </StyledTableCell>
          <StyledTableCell >{row.creator}</StyledTableCell>
          <StyledTableCell  numeric>
              {new Date(row["creation_time"]).toISOString()}
          </StyledTableCell>
          <StyledTableCell >{row.origin}</StyledTableCell>
          <StyledTableCell >{row.job_id}</StyledTableCell>
          <StyledTableCell style={{alignItems:"center"}} >
              <CustomCheckbox
                  checked={checked}
                  onChange={(e,checked) => {
                    setChecked(checked); setCheckboxColor(props.colors[Number(row.protocol_id)]);
                    props.handleViewInGraph(row.protocol_id,row["creation_time"],checked, props.colors[Number(row.protocol_id)]);
                    
                  }}
              />
          </StyledTableCell>
          <StyledTableCell style={{ alignItems:"center", justifyItems:"center"}} >
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </StyledTableCell>
        </StyledTableRow>

        <TableRow style={{marginBottom:"2%", paddingBottom: "2%"}}>
          <StyledSubTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <DeviationTable
                    {...props}
                    deviations={deviations}
                />
            </Collapse>
          </StyledSubTableCell>
        </TableRow>
    
      </React.Fragment>
    );
});



const ProtocolTable = memo((props)=> {

    const colors = makeColors(30)
    const mainColumns = [
        {id:"Protocol Id", label:props.intl.formatMessage({id: "metering_point.protocol_panel.main_cols.protocol_id"}), minWidth:"15%"},
        {id:"Creator", label:props.intl.formatMessage({id: "metering_point.protocol_panel.main_cols.creator"}), minWidth:"20%"},
        {id:"Date", label:props.intl.formatMessage({id: "metering_point.protocol_panel.main_cols.date"}), minWidth:"20%"},
        {id:"Origin", label:props.intl.formatMessage({id: "metering_point.protocol_panel.main_cols.origin"}), minWidth:"15%"},
        {id:"Job Id", label:props.intl.formatMessage({id: "metering_point.protocol_panel.main_cols.job_id"}), minWidth:"15%"},
        {id:"View", label:props.intl.formatMessage({id: "metering_point.protocol_panel.main_cols.view"}), minWidth:"10%"},
        {id:"expand", label:"", minWidth:"5%"},
    ]

    return (
        <TableContainer component={Paper}>
        <Table size="small" style={{flexShrink:1}}>
            {props.protocols.length > 0 ? (
            <React.Fragment>
                <TableHead >
                    <TableRow >
                    {
                        mainColumns.map(col=>(
                        <StyledTableCell style={{minWidth:col.minWidth, color:common.white, backgroundColor: grey[600]}}>
                            <Typography style={{fontSize:16, fontWeight:"bold"}}> {col.label} </Typography>
                        </StyledTableCell>
                        ))
                    }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        props.protocols.map(protocol => (
                            <Row 
                                {...props}
                                colors={colors}
                                row={protocol}
                                handleViewInGraph={props.handleViewInGraph}
                            />
                        ))
                    }
                </TableBody>
            </React.Fragment>
            ) : null}
        </Table>
        </TableContainer>

    );

})

ProtocolTable.propTypes = {
    protocols: PropTypes.array.isRequired,
    handleViewInGraph: PropTypes.func.isRequired,
}

export {
    DeviationTable
}

export default withStyles(styles)(injectIntl(ProtocolTable));

