import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import axios from "axios";
import { ExportToCsv } from "export-to-csv";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import MUIDataTable from "mui-datatables";

import {
  withStyles,
  Tooltip,
  IconButton,
  CircularProgress,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Grid,
} from "@material-ui/core";

import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import styles from "../styles";

class ExportData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      csvDateDialogShow: false,
      csvMeterReadingDataIncludeFrom: this.props.diagramDataIncludeFrom,
      csvMeterReadingDataIncludeTo: this.props.diagramDataIncludeTo,
      populatingCSVData: false,
      csvData: [],
      mergedData: [],
    };
  }

  toggleOrientation = () => {
    let export_sort_orientation = this.state.export_sort_orientation === "desc" ? "asc" : "desc";
    this.setState({ export_sort_orientation });
  };

  mergeData = () => {
    let mergedTable = {};
    let mergedAsArray = [];
    const orgTable = this.state.csvData;
    orgTable.forEach((post) => {
      if (!mergedTable[post.consumption_from] && (post.property === "energy" || post.property === "flow")) {
        mergedTable[post.consumption_from] = {};
      }
      if (post.property === "energy") {
        mergedTable[post.consumption_from]["metering_point_id"] = post.metering_point_id;
        mergedTable[post.consumption_from]["timestamp"] = post.timestamp.replace('T', ' ').replace('Z','');
        mergedTable[post.consumption_from]["consumption_from"] = post.consumption_from.replace('T', ' ').replace('Z','');
        mergedTable[post.consumption_from]["temperature"] = this.roundValue(post.temperature, 3);
        mergedTable[post.consumption_from]["energy_consumption"] = this.roundValue(post.consumption_value, 3);
        mergedTable[post.consumption_from]["energy_calculated_value"] = this.roundValue(post.calculated_value, 3);
        mergedTable[post.consumption_from]["energy_status"] = post.reading_status;
        mergedTable[post.consumption_from]["energy_value"] = post.value;
      }
      if (post.property === "flow") {
        mergedTable[post.consumption_from]["metering_point_id"] = post.metering_point_id;
        mergedTable[post.consumption_from]["timestamp"] = post.timestamp.replace('T', ' ').replace('Z','');
        mergedTable[post.consumption_from]["consumption_from"] = post.consumption_from.replace('T', ' ').replace('Z','');
        mergedTable[post.consumption_from]["temperature"] = this.roundValue(post.temperature, 3);
        mergedTable[post.consumption_from]["flow_consumption"] = this.roundValue(post.consumption_value, 3);
        mergedTable[post.consumption_from]["flow_calculated_value"] = this.roundValue(post.calculated_value, 3);
        mergedTable[post.consumption_from]["flow_status"] = post.reading_status;
        mergedTable[post.consumption_from]["flow_value"] = post.value;
      }
    });
    Object.keys(mergedTable).forEach((post) => {
      let postAsArray = [
        mergedTable[post].metering_point_id,
        mergedTable[post].consumption_from,
        mergedTable[post].energy_consumption,
        mergedTable[post].energy_calculated_value,
        mergedTable[post].energy_status,
        mergedTable[post].temperature,
        mergedTable[post].flow_consumption,
        mergedTable[post].flow_calculated_value,
        mergedTable[post].flow_status,
        mergedTable[post].timestamp,
        mergedTable[post].energy_value,
        mergedTable[post].flow_value,
      ];
      mergedAsArray.push(postAsArray);
    });
    this.setState({ mergedAsArray });
  };

  setActiveSort = (id) => {
    let export_sortby = id;
    this.setState({ export_sortby });
    this.toggleOrientation();
    //this.sortData();
    this.mergeData();
  };

  handleDateInput = (component, value) => {
    var newDate = new Date(value);
    //Adjust time because of DatePicker component timezone inconsistency
    if (newDate.getUTCHours() > 12) {
      newDate.setUTCHours(24);
    } else {
      newDate.setUTCHours(0);
    }
    this.setState({
      [component]: newDate,
    });
  };

  toggleCsvDateDialog = (show) => {
    this.setState({
      csvDateDialogShow: show,
      csvMeterReadingDataIncludeFrom: this.props.diagramDataIncludeFrom,
      csvMeterReadingDataIncludeTo: this.props.diagramDataIncludeTo,
      populatingCSVData: false,
      csvData: [],
    });
  };

  /**
   *
   * @param {int} value The number to be rounded
   * @param {int} number_of_decimals How many decimals to round the number to
   */
  roundValue = (value, number_of_decimals) => {
    try {
      return +value.toFixed(number_of_decimals);
    } catch (error) {
      return null;
    }
  };

  fetchData = () => {
    var thiz = this;
    this.setState({ populatingCSVData: true });

    function fetchMeterReadings(token) {
      var headers = { Authorization: "Bearer " + token };
      let params = {
        include_from: thiz.state.csvMeterReadingDataIncludeFrom.toISOString(),
        include_to: thiz.state.csvMeterReadingDataIncludeTo.toISOString(),
        metering_point_id: thiz.props.meteringpoint.metering_point_id,
        limit: 999999999,
        offset: 0,
      };
      return axios.get(thiz.props.context.config.meterReadingsAPI + "/validated", { headers, params });
    }

    function fetchWeather(token) {
      var headers = { Authorization: "Bearer " + token };
      var weatherEndDate = new Date(thiz.state.csvMeterReadingDataIncludeTo);
      weatherEndDate.setUTCDate(weatherEndDate.getUTCDate() + 1);
      let params = {
        include_from: thiz.state.csvMeterReadingDataIncludeFrom.toISOString(),
        include_to: weatherEndDate.toISOString(),
        weather_station_id: thiz.props.meteringpoint.weather_station_id,
        weather_station_agency: thiz.props.meteringpoint.weather_station_agency,
        limit: 999999999,
        offset: 0,
      };
      return axios.get(thiz.props.context.config.weatherAPI + "/readings", { headers, params });
    }

    this.props.context.keycloak
      .updateToken(5)
      .success(function () {
        axios
          .all([
            fetchMeterReadings(thiz.props.context.keycloak.token),
            fetchWeather(thiz.props.context.keycloak.token)
          ])
          .then(
            axios.spread(function (meteringReadingsResponse, weatherResponse) {
              // Transform weather data to a map
              var temperatures = {};
              for (let temperature of weatherResponse.data) {
                temperatures[temperature.timestamp] = temperature;
              }

              let csvData = [];
              for (let meterReading of meteringReadingsResponse.data) {
                let csvRow = {
                  metering_point_id: meterReading.metering_point_id,
                  timestamp: meterReading.timestamp,
                  value: meterReading.value,
                  unit_of_measure: meterReading.unit_of_measure,
                  property: meterReading.property,
                  reading_status: meterReading.reading_status,
                  consumption_from: meterReading.consumption ? meterReading.consumption.from : undefined,
                  consumption_to: meterReading.consumption ? meterReading.consumption.to : undefined,
                  consumption_value: meterReading.consumption ? meterReading.consumption.value : undefined,
                  calculated_value: meterReading.calculated ? meterReading.calculated.value : undefined,
                  calculated_normalized_value: meterReading.calculated
                    ? meterReading.calculated.normalized_value
                    : undefined,
                  calculated_deviation_type: meterReading.calculated
                    ? meterReading.calculated.deviation_type
                    : undefined,
                  calculated_deviation_level: meterReading.calculated
                    ? meterReading.calculated.deviation_level
                    : undefined,
                  temperature:
                    (temperatures[meterReading.timestamp]) ? 
                      temperatures[meterReading.timestamp].value : undefined
                    // meterReading && meterReading.calculated && meterReading.calculated.weather_data
                    //   ? meterReading.calculated.weather_data.outdoor_temperature
                    //   : undefined,
                };

                // Dynamic columns based on statuses
                if (meterReading.status) {
                  for (let status of meterReading.status) {
                    csvRow[status.key] = status.val;
                  }
                }
                csvData.push(csvRow);
              }

              thiz.setState({
                populatingCSVData: false,
                csvData: csvData,
              });
              thiz.mergeData();
            })
          )
          .catch((error) => {
            console.log(error);
          });
      })
      .error(function () {
        thiz.props.context.keycloak.login();
      });
  };

  downloadCSVData = () => {
    const csvOptions = {
      filename:
        this.props.meteringpoint.metering_point_id +
        "-" +
        this.state.csvMeterReadingDataIncludeFrom.toISOString() +
        "-" +
        this.state.csvMeterReadingDataIncludeTo.toISOString() +
        "_meter_readings",
      fieldSeparator: ";",
      quoteStrings: '"',
      decimalSeparator: ",",
      showLabels: true,
      showTitle: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(this.state.csvData);
  };

  render() {
    const { classes } = this.props;
    const bgColors = { warning: "#F4DFC1", critical: "#D15566" };
    const columns = [
      {
        name: this.props.intl.formatMessage({ id: "metering_points.export.metering_point_id" }),
        label: this.props.intl.formatMessage({ id: "metering_points.export.metering_point_id" }),
        options: {
          filter: false,
          sort: false,
        },
      },

      {
        name: this.props.intl.formatMessage({ id: "metering_points.export.consumption_from" }),
        label: this.props.intl.formatMessage({ id: "metering_points.export.consumption_from" }),
        options: {
          filter: false,
          sort: true,
        },
      },

      {
        name: this.props.intl.formatMessage({ id: "metering_points.export.energy" }),
        label: this.props.intl.formatMessage({ id: "metering_points.export.energy" }),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value === 0.0) {
              return <Typography style={{ backgroundColor: bgColors["warning"] }}>{value}</Typography>;
            } else {
              return <Typography>{value}</Typography>;
            }
          },
        },
      },
      {
        name: this.props.intl.formatMessage({ id: "metering_points.export.ref_energy" }),
        label: this.props.intl.formatMessage({ id: "metering_points.export.ref_energy" }),
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: this.props.intl.formatMessage({ id: "metering_points.export.energy_status" }),
        label: this.props.intl.formatMessage({ id: "metering_points.export.energy_status" }),
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value === "calculated") {
              return <Typography style={{ backgroundColor: bgColors["critical"] }}>
                {this.props.intl.formatMessage({ id: `metering_points.export.${value}` })}
              </Typography>;
            } else {
              return <Typography>
                {this.props.intl.formatMessage({ id: `metering_points.export.${value}` })}
              </Typography>;
            }
          },
        },
      },

      {
        name: this.props.intl.formatMessage({ id: "metering_points.export.temperature" }),
        label: this.props.intl.formatMessage({ id: "metering_points.export.temperature" }),
        options: {
          filter: false,
          sort: false,
        },
      },

      {
        name: this.props.intl.formatMessage({ id: "metering_points.export.flow" }),
        label: this.props.intl.formatMessage({ id: "metering_points.export.flow" }),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value === 0.0) {
              return <Typography style={{ backgroundColor: bgColors["warning"] }}>{value}</Typography>;
            } else {
              return <Typography>{value}</Typography>;
            }
          },
        },
      },
      {
        name: this.props.intl.formatMessage({ id: "metering_points.export.ref_flow" }),
        label: this.props.intl.formatMessage({ id: "metering_points.export.ref_flow" }),
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: this.props.intl.formatMessage({ id: "metering_points.export.flow_status" }),
        label: this.props.intl.formatMessage({ id: "metering_points.export.flow_status" }),
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value === "calculated") {
              return <Typography style={{ backgroundColor: bgColors["critical"] }}>
                {this.props.intl.formatMessage({ id: `metering_points.export.${value}` })}
              </Typography>;
            } else {
              return <Typography>
                {this.props.intl.formatMessage({ id: `metering_points.export.${value}` })}
              </Typography>;
            }
          },
        },
      },

      {
        name: this.props.intl.formatMessage({ id: "metering_points.export.timestamp" }),
        label: this.props.intl.formatMessage({ id: "metering_points.export.timestamp" }),
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: this.props.intl.formatMessage({ id: "metering_points.export.energy_value" }),
        label: this.props.intl.formatMessage({ id: "metering_points.export.energy_value" }),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value === 0.0) {
              return <Typography style={{ backgroundColor: bgColors["warning"] }}>{value}</Typography>;
            } else {
              return <Typography>{value}</Typography>;
            }
          },
        },
      },
      {
        name: this.props.intl.formatMessage({ id: "metering_points.export.flow_value" }),
        label: this.props.intl.formatMessage({ id: "metering_points.export.flow_value" }),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value === 0.0) {
              return <Typography style={{ backgroundColor: bgColors["warning"] }}>{value}</Typography>;
            } else {
              return <Typography>{value}</Typography>;
            }
          },
        },
      },

    ];
    const data = this.state.mergedAsArray;
    const options = {
      filterType: "checkbox",
      responsive: "scrollMaxHeigh",
      elevation: 0,
      // filterType: "multiselect",
      // selectableRows: "none",  //TODO Perhaps change if we want slimmer rows
      // viewColumns: true,
      rowsPerPageOptions: [10, 14, 30],
      textLabels: this.props.intl.messages["MUIDataTables.textLabels"],
      setTableProps: () => {return {size: 'small'}}
    };

    function createDiagramsDatePicker(langFormat, labelFMID, onChange, value) {
      return (
        <KeyboardDatePicker
          className={classes.DatePicker + " " + classes.MarginRight}
          allowKeyboardControl
          autoOk
          disableFuture
          invalidDateMessage={langFormat({
            id: "invalid_date_message",
          })}
          minDateMessage={langFormat({
            id: "min_date_message",
          })}
          maxDateMessage={langFormat({
            id: "max_date_message",
          })}
          label={langFormat({
            id: labelFMID,
          })}
          InputLabelProps={{ shrink: true }}
          format="YYYY-MM-DD"
          onChange={onChange}
          value={value}
        />
      );
    }

    return (
      <React.Fragment>
        <Tooltip
          title={this.props.intl.formatMessage({
            id: "metering_points.download_csv",
          })}
        >
          <IconButton
            onClick={() => this.toggleCsvDateDialog(true)}
            disabled={this.state.populatingCSVData}
            className={classes.MarginRight}
          >
            <CloudDownloadIcon />
            {this.state.populatingCSVData ? <CircularProgress size={36} className={classes.CircularProgress} /> : ""}
          </IconButton>
        </Tooltip>
        <Dialog fullScreen open={this.state.csvDateDialogShow} onClose={() => this.toggleCsvDateDialog(false)}>
          <DialogTitle>
            <FormattedMessage id="metering_points.download_csv" />
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={9}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  {createDiagramsDatePicker(
                    this.props.intl.formatMessage,
                    "metering_points.download_csv_from_date",
                    (value) => this.handleDateInput("csvMeterReadingDataIncludeFrom", value),
                    this.state.csvMeterReadingDataIncludeFrom
                  )}
                  {createDiagramsDatePicker(
                    this.props.intl.formatMessage,
                    "metering_points.download_csv_to_date",
                    (value) => this.handleDateInput("csvMeterReadingDataIncludeTo", value),
                    this.state.csvMeterReadingDataIncludeTo
                  )}
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={3}>
                <ButtonGroup variant="contained">
                  <Button onClick={this.fetchData} color="primary">
                    {this.props.intl.formatMessage({ id: "metering_points.download_show_data" })}
                  </Button>
                  <Button onClick={this.downloadCSVData} color="primary" disabled={this.state.csvData.length === 0}>
                    {this.props.intl.formatMessage({
                      id: "metering_points.download_csv",
                    })}
                  </Button>
                  <Button onClick={() => this.toggleCsvDateDialog(false)} color="primary">
                    {this.props.intl.formatMessage({
                      id: "cancel",
                    })}
                  </Button>
                </ButtonGroup>
              </Grid>
              <Grid item xs={12}>
                <MUIDataTable
                  title={this.props.intl.formatMessage({ id: "metering_points.export.aggregated_data" })}
                  data={data}
                  columns={columns}
                  options={options}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(injectIntl(ExportData));
